import { useState } from 'react';
import { client } from '../utilities/Database.mjs';
import { extractErrorText } from '../fleet-shared/Common.mjs';

const listTasks = /* GraphQL */ `
  query ListTaskPogoTasks(
    $filter: ModelTaskPogoTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskPogoTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        dueDate
      }
      nextToken
    }
  }
`;

const Test = () => {
  let [message, setMessage] = useState('Ready to update all tasks.');

  // A function that will be executed when the button is pressed
  async function handleClick() {
    console.log('Querying all tasks.');

    let nextToken = null;

    do {
      try {
        // Make a graphql call to list all the TaskPogoTasks.
        const response = await client.graphql({ query: listTasks, variables: {  nextToken: nextToken } });
        let data = response.data.listTaskPogoTasks.items;
        nextToken = response.data.listTaskPogoTasks.nextToken;
        let i = 0;

        setMessage(`Updating ${data.length} tasks.`);

        for (let task of data) {
          // console.log(task);
          let updatedTask = {
            id: task.id,
            assignedEmail: "",
            priority: 0,
            rank: 100 * i,
            dueDate: task.dueDate,
            cost: -1
          }

          // Update the task in the database
          await client.graphql({
            query: `mutation updateTaskPogoTask($input: UpdateTaskPogoTaskInput!) {
              updateTaskPogoTask(input: $input) {
                id
                assignedEmail
                priority
                rank
                dueDate
                cost
              }
            }`,
            variables: { input: updatedTask }
          });

          i++;
          setMessage(`Updated ${i} tasks.`);
        }
      } catch(err) {
        console.error(err);
        setMessage(`Error: ${extractErrorText(err)}`);
        nextToken = null;
      }
    } while (nextToken);
  }

  return (
    <div>
      <h1>Test Page</h1>
      <button onClick={() => handleClick()}>Click me</button>
      <p>{message}</p>
    </div>  
  );
};

export default Test;