// Builds the breadcrumb UI for top of pages with Item's by looking back at this item's parents
//  props.item is the  item building BreadCrumb's for (uses just item.parentItem to walk up the tree)
//  props.extras is an array of extra crumbs ({name,url}) to add at end of the crumbs.

import { Breadcrumb } from "react-bootstrap";
import { urlForItem, urlForAllItems } from '../../fleet-shared/Urls.mjs';
import { useNavigate } from "react-router-dom";

export const ItemBreadcrumbs = ( { item, extras, navigateTo } ) => {
  const navigate = useNavigate();

  function crumbClicked(crumb) {
    if (crumb.url) {
      navigate(crumb.url);
    } else if (crumb.page && crumb.itemId) {
      navigateTo(crumb.page, crumb.itemId)
    } else {
      console.error(`Crumb with no nav? ${crumb.name}`);
    }
  }

  const Extras = ({extras}) => {
    if (extras) {
      return extras.map( (crumb, index) => (
        <Breadcrumb.Item key={index} onClick={()=>crumbClicked(crumb)}>
          {crumb.name}
        </Breadcrumb.Item>
      ));
    }
    return null;
  }

  // TODO: Do this at init time, not render time.

  if (item != null) {
    var crumbs = [];

    var parent = item.parentItem;
    while (parent != null) {
      crumbs = [
        {
          name: parent.name, 
          url: urlForItem(parent.id)
        }, 
        ...crumbs
      ];
      parent = parent.parentItem;
    }

    crumbs = [
      {
        name: 'Items', 
        url: urlForAllItems()
      }, 
      {
        name: item.name,
        url: urlForItem(item.id)
      },
      ...crumbs
    ];

    return (
      <div className="container">
        <Breadcrumb>
          {
            crumbs.map( (crumb, index) => (
              <Breadcrumb.Item key={crumb.url} onClick={()=>crumbClicked(crumb)}>
                {crumb.name}
              </Breadcrumb.Item>
            ))
          }
          <Extras extras={extras}/>
        </Breadcrumb>
      </div>
    )
  } else {
    return null;
  }
};
