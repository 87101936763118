
export const completedTaskFields = [
    {name: 'Date',            key: 'date',             type: 'date' },
    {name: 'Counter',         key: 'counter',          type: 'number' },
    {name: 'Name',            key: 'name',             type: 'text' },
    {name: 'Description',     key: 'description',      type: 'text' },
    {name: 'Notes',           key: 'notes',            type: 'text' },
    {name: 'Was due counter', key: 'dueCounter',       type: 'number' },
    {name: "Counter Based On",key: "itemCounterId",    type: "select" },
    {name: 'Was due date',    key: 'dueDate',          type: 'date' },
    {name: 'Counter Interval',key: 'counterInterval',  type: 'number' },
    {name: 'Days Interval',   key: 'daysInterval',     type: 'number' }
];
