import { itemFields } from '../fleet-shared/ItemFields.mjs';
import DataCard from './DataCard';
import CardHeader from './CardHeader';
import { Thumbnail, ThumbnailMedium } from '../pages/item/Thumbnail';
import { displayDate } from '../fleet-shared/Common';

const Body = ({ showFields, identityId, itemId }) => {
  return (
    <div className="d-flex justify-content-center flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <Thumbnail identityId={identityId} itemId={itemId} size={ThumbnailMedium} />

      <div className="table-responsive">
        <table className="table table-hover text-nowrap table-sm">
          <tbody>
            {
              showFields.map((field, index) => (
                <tr key={field.name} >
                  <td align="right" className="col-6">{field.name}</td>
                  <td align="left" className="col-6 entered-data">{field.data}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}

// All the fields in this piece of equipment displayed.
const EquipmentInfoUI = ({ item, identityId, deleteTapped, editTapped, emailTapped }) => {
  // Get all the non-blank fields, not including the name (first field)
  const noNameItems = itemFields.slice(1);
  let showFields = noNameItems.filter( (field) => {
    return (item[field.key] && item[field.key].length > 0);
  }).map( (field, index) => {
    let data = item[field.key];
    if (field.type === 'date') {
      data = displayDate(data);
    }
    return {name: field.name, data: data}
  });

  return (
    <div>
      <DataCard
        header={<CardHeader text={item.name} deleteTapped={deleteTapped} editTapped={editTapped} emailTapped={emailTapped} />}
        body={<Body showFields={showFields} itemId={item.id} identityId={identityId} />}
      />
    </div>
  );
}

export default EquipmentInfoUI;