// Component to show a confirmation dialog. Has an embedded StatusMessage that the
// caller can show UI when the user confirms. I use this UI to show status of
// async calls to server before dismissing the confirm UI.
//
// Pass in props:
//      show - Show/hide dialog
//      title - Title for dialog
//      body - body for dialog
//      confirmText - Text for footer button to confirm.
//      onConfirm - function to call when confirm button tapped. Passed StatusMessage UI functions...
//      onHide - function to call when cancel button is hit

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useStatusMessage } from './StatusMessage';


function Confirm(props) {
    const [StatusMessage, {showText, showError, showLoading, hideStatus}] = useStatusMessage();

    var cancelButton = props.showCancelButton ? (
            <Button className='btn btn-primary' onClick={props.onHide}>
                {props.cancelText}
            </Button>
        ) : null;

    // User confirmed, call the callback with UI elements.
    function handleConfirm() {
      props.onConfirm({ 
        showText: showText,
        showError: showError,
        hideStatus: hideStatus,
        showLoading: showLoading
      });
    }

    return (
        <Modal show={props.show}>
            <Modal.Header>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{props.body}</Modal.Body>
            <Modal.Footer>
                {cancelButton}
                <Button className='btn btn-danger' onClick={handleConfirm}>
                    {props.confirmText}
                </Button>
                <StatusMessage/>
            </Modal.Footer>
        </Modal>
    )
}


Confirm.defaultProps = {
    cancelText: 'Cancel',
    confirmText: 'Confirm',
    confirmBSStyle: 'btn-danger',
    showCancelButton: true
};

export default Confirm;