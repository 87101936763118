
import { PrimaryText } from '../../components';

// Help text displayed when logbook is empty. Used in a couple of places, so made
// into a component.
export const LogbookHelp = () => (
    <PrimaryText>
        <b>
            <p>The logbook tracks completed tasks.</p>
            <p>Add an entry by clicking the &lsquo;Add Entry&rsquo; button below,
            or when viewing a task or another logbook entry.</p>
        </b>
    </PrimaryText>
)