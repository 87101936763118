// Loads a list of top level items from the database and shows them.
// All Items that don't have a parentId associated with them.
// If you choose a top level item, loads the Item page, with the ID of that item passed to the Item page. 

import React, { useCallback } from 'react';
import { saveEditedItem, createItem, NewItemName, client } from '../../utilities/Database';
import { useStatusMessage, DataCard, ModalEditForm, ItemsHelp } from '../../components';
import { itemFields } from '../../fleet-shared/ItemFields';
import { CardHeader } from '../../components/CardHeader';
import { useMyInfiniteScroll } from '../../components/MyInfiniteScroll';
import { ItemRow } from './ItemRow';

const listTaskPogoItems = /* GraphQL */ `
  query ListTaskPogoItems(
    $filter: ModelTaskPogoItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskPogoItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
      }
      nextToken
    }
  }
`;

// Page of Items to select. Shows status for each item (# of tasks coming due, etc.)
const Items = ( { user } ) => {

  // Edit Item UI.  When to show it, and what to show.
  const [showEditItem, setShowEditItem] = React.useState(false);
  const [editItem, setEditItem] = React.useState(null);

  // Loading/error UI
  const [StatusMessage, {showText, showError, hideStatus}] = useStatusMessage();

  const fetchItems = useCallback(async (token) => {
    const filter = {
      or: [
        {parentId: { attributeExists: false } },
        { parentId: { eq: null } }
      ]
    };

    const itemsData = await client.graphql({ query: listTaskPogoItems, variables: { filter: filter, nextToken: token }});
    const items = itemsData.data.listTaskPogoItems.items;
    const nextToken = itemsData.data.listTaskPogoItems.nextToken;

    return { items, nextToken };
  }, []);

  const renderChildren = useCallback((items) => {
    return (
      <div className="table-responsive">
      <table className="table table-hover text-nowrap table-sm">
        <tbody>
          {items.map((item, index) => 
            <ItemRow key={index} identityId={user && user.identityId} itemId={item.id} name={item.name}/>)
          }
        </tbody>
      </table>
    </div>
    );
  }, [user]);

  const { Control, entries, setEntries } = useMyInfiniteScroll(
    renderChildren, 
    fetchItems,
    null
  );


  // Passed to EditItem.  When the Save button is pressed, this is called.
  // throws on errors.
  const saveItem = useCallback(async (newItem) => {
    // Go do the update -- can throw!
    const savedItem = await saveEditedItem(newItem, editItem);

    // Update the UI.
    var found = false;
    const cItems = entries.length;
    for (var i=0; i < cItems; i++) {
      if (entries[i].id === savedItem.id) {
        entries[i] = savedItem;
        found = true;
        break;
      }
    }
    if (!found) {
      setEntries([...entries, savedItem]);
    }

    setShowEditItem(false);
  }, [editItem, entries, setEntries]);


  // User tapped add item button.
  const addItem = useCallback(async () => {
    showText("Creating...");

    try {
      // Add item to the database
      let newItem = {
        name: NewItemName,
      };
      // Note: this can throw (e.g. network or security error)
      const createdItem = await createItem(newItem);

      // Put up UI to edit the new item
      setEditItem(createdItem);
      setShowEditItem(true);
      hideStatus();
    } catch(err) {
      showError(err);
    }
  }, [hideStatus, showError, showText]);

  // TODO: Below I do user && user.identityId. I seem to lose "user" on a fault. I think
  // I should redirect to home???
  return (
    <>
      <StatusMessage/>
      
      <DataCard
        header = <CardHeader text='Items' addTapped={addItem} />
        body = <Control/>
      />

      <ItemsHelp/>

      <ModalEditForm
        show={showEditItem}
        fields={itemFields}
        onHide={() => setShowEditItem(false)}
        item={editItem}
        save={saveItem}
      />
    </>
  )
}  

export default Items

