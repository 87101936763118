// Thumbnail support for an Item.
//
// State:
//  back - Optional Url to go to after successful upload or cancel. Goes to all items if not specified.

import { StorageManager } from '@aws-amplify/ui-react-storage';
import { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Confirm, Spinner, ErrorText } from '../../components';
import { 
  urlForItemThumbnailUpload,
  urlForItemThumbnailSmall, 
  urlForItemThumbnailMedium, 
  thumbnailPrefix,
  urlForItemStorage,
  ThumbnailSmallSize, 
  ThumbnailMediumSize } from '../../fleet-shared/Urls';
import '@aws-amplify/ui-react/styles.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { storageDelete, storageGet, storageList } from '../../utilities/Database';
import { thumbnailMediumSuffix, thumbnailSmallSuffix, urlForItemImage } from '../../fleet-shared/Urls.mjs';

import { TaskPogoImageProcessor } from '../../graphql/mutations'; 
import { generateClient } from 'aws-amplify/api';
import { useStorageURL } from './useStorageURL';
import { list } from 'aws-amplify/storage';

const client = generateClient();
const NotFoundError = 'NotFound';

// Modal component for uploading a thumbnail for an item.
export const ModalThumbnailUpload = ({ 
  show,       // Show / Hide modal UI.
  onHide,     // Called to hide.
  itemId,     // ItemId showing the UI for.
  didUpload   // Callback on successful file upload didUpload({key, name}).
}) => {
  // Called by StorageManager when upload is successful.
  function uploadSuccess(file) {
    console.log(`Uploaded ${JSON.stringify(file)}`)
    if (didUpload) {
      didUpload(file);
    }

    onHide();
  }

  // Called by StorageManager when uploading a file. Add the bucket and "thumbnail-" in front of filename.
  function processFile(keyFile) {
    return { 
      key: urlForItemThumbnailUpload(itemId, keyFile.key),
      file: keyFile.file
    }
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Upload Item Image</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className='container text-center'>
          <StorageManager
            acceptedFileTypes={['image/*']}
            accessLevel='private'
            maxFileCount={1}
            maxFileSize={10000000}
            onUploadSuccess={(key) => { uploadSuccess(key); }}
            processFile={processFile}
            isResumable
          />

          <Button variant="danger" className="mx-2 m-2" onClick={onHide}>
            Cancel
          </Button>
         </div>
      </Modal.Body>
    </Modal>

  );
}


// Component shown when there is no Thumbnail.
const ThumbnailFallback = ({ size, itemId, setShowUpload, errorName }) => {
  function uploadImage() {
    setShowUpload(true);
  }

  if (errorName === '') {
    return null;
  }


  // Showing a true thumbnail? Just show a generic image.
  // Showing a true thumbnail? Just show a generic image.
  if (size === ThumbnailSmall) {
    return (
      <i className="fa-solid fa-image mt-1"></i>
    );
  } 

  // Otherwise show UI to let the user upload.
  return <div className='container'>
    { errorName !== NotFoundError ? <div className='row'><ErrorText text={errorName}/></div> : null}
    { size !== ThumbnailLarge ? 
      <Button variant="primary" className="mb-2" onClick={uploadImage}>
        Upload Image
      </Button>
    : 
      null
    }
  </div>
}



// If have a thumbnail, this is the component that renders.
// Shows the image at the given URL. And if not a small thumbnail buttons
// to edit/delete it.
const ThumbnailImage = ( { itemId, size, url, onEdit, onDelete, errorName } ) => {
  const navigate = useNavigate();

  function gotoFullSize() {
    navigate(urlForItemImage(itemId));
  }

    // If there's an error, the ThumbnailFallback will render instead, 
  if (errorName !== '') { 
    return null;
  }

  // If the image's URL in S3 hasdn't been resolved yet, show a spinner while we get it.
  if (url === null) {
    return <div className='text-center'><Spinner/></div>
  } 

    // For small thumbs, just show a small image.
  if (size === ThumbnailSmall) {
    return <img src={url} alt='Item' className='thumbnail' width={ThumbnailSmallSize} height={ThumbnailSmallSize}/>
  } else if (size === ThumbnailMedium) {
    // For medium, we're on the Item page. Show larger imager, edit button, delete button, and let user click on image for bigger.
    return (
        <div onClick={gotoFullSize} >
          <img 
            src={url} 
            alt='Item' 
            className='thumbnail-medium' 
            width={ThumbnailMediumSize} 
            height={ThumbnailMediumSize}
          />
          <div className='thumbnail-expand d-flex align-items-center'>
            <i className="fa-solid fa-up-right-and-down-left-from-center"></i>
          </div>
          <div className='thumbnail-edit d-flex align-items-center'>
            <Button variant='info' className='mx-2 m-2' size='sm' onClick={onEdit}>
              Edit
            </Button>
            <i className='fa fa-trash col-1' aria-hidden='true' onClick={onDelete}></i>
          </div>
        </div>
    );
  } else {
    // For large, just show the image.
    return <img src={url} alt='Item' />
    
  }
}

export const ThumbnailLarge = "large";
export const ThumbnailMedium = "medium";
export const ThumbnailSmall = "small";

// Key to to thumbnail in storage. Known value if small/medium. Got to look it up if large...
function initialKey(size, itemId) {
}

// Component to display a thumbnail for an item or some fallback UI.
export const Thumbnail = ( { identityId, itemId, size  } ) => {
  const [errorName, setErrorName] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [key, setKey] = useState(initialKey(size, itemId));

  const handleError = useCallback((error) => {
    setErrorName(error.name);
  }, [setErrorName]);

  const [url, setURL] = useStorageURL({
      key: key,
      onStorageGetError: handleError,
  });

  // Figure out the key to use for the image. If it's small/medium we're looking for, it's
  // a known key. If large, have to list files in S3 to find the original file (I keep its
  // original name).
  useEffect(() => {
    var ignore = false;

    async function findKey() {
      try {
        // Get all thumbnail files.
        const response = await list({
          prefix: `${itemId}/${thumbnailPrefix}`,
          options: {
            accessLevel: 'private',
            listAll: true
          }
        });

        if (ignore) { return }

        // Iterate through files, find the first file that isn't the small/medium (there should be only one).
        response.items.forEach((item) => {
          if (!item.key.endsWith(thumbnailSmallSuffix) && !item.key.endsWith(thumbnailMediumSuffix)) {
            // Found the original, set the key and bail.
            setKey(item.key);
            return;
          }
        })
      } catch (error) {
        console.error('Error listing Thumbnail files', error);
        setErrorName(error.message || error);
      }
    }

    // Small/Medium have hardcoded file names.
    if (size === ThumbnailMedium) {
      setKey(urlForItemThumbnailMedium(itemId));
    } else if (size === ThumbnailSmall) {
      setKey(urlForItemThumbnailSmall(itemId));
    } else {
      // Large needs to go look for the name on the server.
      findKey();
    }

    // If unmounted, bail after returning from the async operation above.
    return () => { 
      ignore = true;
    }
  }, [itemId, size]);

  // Edit hit, show the upload UI.
  function onEditThumbnail() {
    setShowUpload(true);
  }

  // User hit the delete button, confirm they want to.
  function onDeleteThumbnail() {
    setShowConfirm(true);
  }

  // User confirmed delete, go do it (and show UI while).
  async function onDeleteThumbnailConfirmed({ showText, showError, hideStatus }) {
    try {
      // Show UI that the file is being deleted.
      showText('Deleting...');

      // Delete the thumbnail files.
      // I need the filename for the uploaded file, so first list...
      // TODO: Do in parallel.
      let files = await storageList(urlForItemStorage(itemId));
      for (var i=0; i < files.length; i++) {
        if (files[i].key.includes(thumbnailPrefix)) {
          await storageDelete(files[i].key);
        }
      }

      // Update the UI -- no file, no confirm UI, show UI for file not found.
      setErrorName(NotFoundError);
      setURL(null);
      hideStatus();
      setShowConfirm(false);
    } catch (err) {
      showError(err);
    }
  }


  // Called when uploaded a thumbnail. Ask server to create the various thumbnails then show the medium one.
  async function thumbnailUploaded(file) {
    try {
      setErrorName('Finishing upload');

      let res = await client.graphql({ 
        query: TaskPogoImageProcessor, 
        variables: {
          userId: identityId,
          itemId: itemId,
          filename: file.key.slice(itemId.length+1)
        }
      });

      if (res.data.TaskPogoImageProcessor !== 'Success') {
        throw new Error(res.data.TaskPogoImageProcessor);
      }

      // Get the URL, throw if doesn't exist.
      let f = await storageGet(key, true);
      setURL(f.url.toString());
      setErrorName('');
    } catch (err) {
      if (err.errors && err.errors.length > 0) {
        setErrorName(err.errors[0].message || err);
      } else {
        setErrorName(err);
      }
    }

  }


  var imageClass = 'thumbnail';
  if (size === ThumbnailMedium) {
    imageClass = 'thumbnail-medium';
  } else if (size === ThumbnailLarge) {
    imageClass = '';
  }

  // If no thumnail, shows ThumbnailFallback,
  // otherwise a StorageImage of the thumbnail.

  return (
    <div className={imageClass}>
      <ThumbnailFallback 
        size={size} 
        itemId={itemId} 
        setShowUpload={setShowUpload}
        errorName={errorName}
      />

      <ThumbnailImage 
        itemId={itemId}
        size={size} 
        url={url}
        onEdit={onEditThumbnail}
        onDelete={onDeleteThumbnail}
        errorName={errorName}
      />

      <ModalThumbnailUpload
        show={showUpload}
        onHide={()=>setShowUpload(false)}
        itemId={itemId}
        didUpload={thumbnailUploaded}
      />

      <Confirm
        show={showConfirm}
        onConfirm={onDeleteThumbnailConfirmed}
        onHide = {() => setShowConfirm(false)}
        body='Are you sure?'
        confirmText='Confirm Delete'
        title={'Delete image'}>
      </Confirm>

    </div>
  );
}

