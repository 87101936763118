/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const TaskPogoImageProcessor = /* GraphQL */ `
  mutation TaskPogoImageProcessor(
    $userId: String
    $itemId: String
    $filename: String
  ) {
    TaskPogoImageProcessor(
      userId: $userId
      itemId: $itemId
      filename: $filename
    )
  }
`;
export const createTaskPogoItem = /* GraphQL */ `
  mutation CreateTaskPogoItem(
    $input: CreateTaskPogoItemInput!
    $condition: ModelTaskPogoItemConditionInput
  ) {
    createTaskPogoItem(input: $input, condition: $condition) {
      id
      name
      description
      manufacturer
      serialNumber
      partNumber
      location
      notes
      dateInstalled
      counterInstalled
      parentId
      itemCounter {
        nextToken
        __typename
      }
      tasks {
        nextToken
        __typename
      }
      completedTasks {
        nextToken
        __typename
      }
      parentItem {
        id
        name
        description
        manufacturer
        serialNumber
        partNumber
        location
        notes
        dateInstalled
        counterInstalled
        parentId
        owner
        createdAt
        updatedAt
        __typename
      }
      childItems {
        nextToken
        __typename
      }
      emails {
        nextToken
        __typename
      }
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTaskPogoItem = /* GraphQL */ `
  mutation UpdateTaskPogoItem(
    $input: UpdateTaskPogoItemInput!
    $condition: ModelTaskPogoItemConditionInput
  ) {
    updateTaskPogoItem(input: $input, condition: $condition) {
      id
      name
      description
      manufacturer
      serialNumber
      partNumber
      location
      notes
      dateInstalled
      counterInstalled
      parentId
      itemCounter {
        nextToken
        __typename
      }
      tasks {
        nextToken
        __typename
      }
      completedTasks {
        nextToken
        __typename
      }
      parentItem {
        id
        name
        description
        manufacturer
        serialNumber
        partNumber
        location
        notes
        dateInstalled
        counterInstalled
        parentId
        owner
        createdAt
        updatedAt
        __typename
      }
      childItems {
        nextToken
        __typename
      }
      emails {
        nextToken
        __typename
      }
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTaskPogoItem = /* GraphQL */ `
  mutation DeleteTaskPogoItem(
    $input: DeleteTaskPogoItemInput!
    $condition: ModelTaskPogoItemConditionInput
  ) {
    deleteTaskPogoItem(input: $input, condition: $condition) {
      id
      name
      description
      manufacturer
      serialNumber
      partNumber
      location
      notes
      dateInstalled
      counterInstalled
      parentId
      itemCounter {
        nextToken
        __typename
      }
      tasks {
        nextToken
        __typename
      }
      completedTasks {
        nextToken
        __typename
      }
      parentItem {
        id
        name
        description
        manufacturer
        serialNumber
        partNumber
        location
        notes
        dateInstalled
        counterInstalled
        parentId
        owner
        createdAt
        updatedAt
        __typename
      }
      childItems {
        nextToken
        __typename
      }
      emails {
        nextToken
        __typename
      }
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTaskPogoTask = /* GraphQL */ `
  mutation CreateTaskPogoTask(
    $input: CreateTaskPogoTaskInput!
    $condition: ModelTaskPogoTaskConditionInput
  ) {
    createTaskPogoTask(input: $input, condition: $condition) {
      id
      name
      assignedEmail
      priority
      rank
      cost
      itemId
      item {
        id
        name
        description
        manufacturer
        serialNumber
        partNumber
        location
        notes
        dateInstalled
        counterInstalled
        parentId
        owner
        createdAt
        updatedAt
        __typename
      }
      description
      notes
      dueCounter
      dueDate
      counterInterval
      daysInterval
      itemCounterId
      itemCounter {
        id
        name
        counter
        itemId
        owner
        createdAt
        updatedAt
        __typename
      }
      completedTasks {
        nextToken
        __typename
      }
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTaskPogoTask = /* GraphQL */ `
  mutation UpdateTaskPogoTask(
    $input: UpdateTaskPogoTaskInput!
    $condition: ModelTaskPogoTaskConditionInput
  ) {
    updateTaskPogoTask(input: $input, condition: $condition) {
      id
      name
      assignedEmail
      priority
      rank
      cost
      itemId
      item {
        id
        name
        description
        manufacturer
        serialNumber
        partNumber
        location
        notes
        dateInstalled
        counterInstalled
        parentId
        owner
        createdAt
        updatedAt
        __typename
      }
      description
      notes
      dueCounter
      dueDate
      counterInterval
      daysInterval
      itemCounterId
      itemCounter {
        id
        name
        counter
        itemId
        owner
        createdAt
        updatedAt
        __typename
      }
      completedTasks {
        nextToken
        __typename
      }
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTaskPogoTask = /* GraphQL */ `
  mutation DeleteTaskPogoTask(
    $input: DeleteTaskPogoTaskInput!
    $condition: ModelTaskPogoTaskConditionInput
  ) {
    deleteTaskPogoTask(input: $input, condition: $condition) {
      id
      name
      assignedEmail
      priority
      rank
      cost
      itemId
      item {
        id
        name
        description
        manufacturer
        serialNumber
        partNumber
        location
        notes
        dateInstalled
        counterInstalled
        parentId
        owner
        createdAt
        updatedAt
        __typename
      }
      description
      notes
      dueCounter
      dueDate
      counterInterval
      daysInterval
      itemCounterId
      itemCounter {
        id
        name
        counter
        itemId
        owner
        createdAt
        updatedAt
        __typename
      }
      completedTasks {
        nextToken
        __typename
      }
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTaskPogoCompletedTask = /* GraphQL */ `
  mutation CreateTaskPogoCompletedTask(
    $input: CreateTaskPogoCompletedTaskInput!
    $condition: ModelTaskPogoCompletedTaskConditionInput
  ) {
    createTaskPogoCompletedTask(input: $input, condition: $condition) {
      id
      name
      cost
      fixByEmail
      itemId
      date
      item {
        id
        name
        description
        manufacturer
        serialNumber
        partNumber
        location
        notes
        dateInstalled
        counterInstalled
        parentId
        owner
        createdAt
        updatedAt
        __typename
      }
      itemCounterId
      itemCounter {
        id
        name
        counter
        itemId
        owner
        createdAt
        updatedAt
        __typename
      }
      taskId
      Task {
        id
        name
        assignedEmail
        priority
        rank
        cost
        itemId
        description
        notes
        dueCounter
        dueDate
        counterInterval
        daysInterval
        itemCounterId
        owner
        createdAt
        updatedAt
        __typename
      }
      description
      taskNotes
      dueCounter
      dueDate
      counterInterval
      daysInterval
      notes
      counter
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTaskPogoCompletedTask = /* GraphQL */ `
  mutation UpdateTaskPogoCompletedTask(
    $input: UpdateTaskPogoCompletedTaskInput!
    $condition: ModelTaskPogoCompletedTaskConditionInput
  ) {
    updateTaskPogoCompletedTask(input: $input, condition: $condition) {
      id
      name
      cost
      fixByEmail
      itemId
      date
      item {
        id
        name
        description
        manufacturer
        serialNumber
        partNumber
        location
        notes
        dateInstalled
        counterInstalled
        parentId
        owner
        createdAt
        updatedAt
        __typename
      }
      itemCounterId
      itemCounter {
        id
        name
        counter
        itemId
        owner
        createdAt
        updatedAt
        __typename
      }
      taskId
      Task {
        id
        name
        assignedEmail
        priority
        rank
        cost
        itemId
        description
        notes
        dueCounter
        dueDate
        counterInterval
        daysInterval
        itemCounterId
        owner
        createdAt
        updatedAt
        __typename
      }
      description
      taskNotes
      dueCounter
      dueDate
      counterInterval
      daysInterval
      notes
      counter
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTaskPogoCompletedTask = /* GraphQL */ `
  mutation DeleteTaskPogoCompletedTask(
    $input: DeleteTaskPogoCompletedTaskInput!
    $condition: ModelTaskPogoCompletedTaskConditionInput
  ) {
    deleteTaskPogoCompletedTask(input: $input, condition: $condition) {
      id
      name
      cost
      fixByEmail
      itemId
      date
      item {
        id
        name
        description
        manufacturer
        serialNumber
        partNumber
        location
        notes
        dateInstalled
        counterInstalled
        parentId
        owner
        createdAt
        updatedAt
        __typename
      }
      itemCounterId
      itemCounter {
        id
        name
        counter
        itemId
        owner
        createdAt
        updatedAt
        __typename
      }
      taskId
      Task {
        id
        name
        assignedEmail
        priority
        rank
        cost
        itemId
        description
        notes
        dueCounter
        dueDate
        counterInterval
        daysInterval
        itemCounterId
        owner
        createdAt
        updatedAt
        __typename
      }
      description
      taskNotes
      dueCounter
      dueDate
      counterInterval
      daysInterval
      notes
      counter
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTaskPogoItemCounter = /* GraphQL */ `
  mutation CreateTaskPogoItemCounter(
    $input: CreateTaskPogoItemCounterInput!
    $condition: ModelTaskPogoItemCounterConditionInput
  ) {
    createTaskPogoItemCounter(input: $input, condition: $condition) {
      id
      name
      counter
      itemId
      item {
        id
        name
        description
        manufacturer
        serialNumber
        partNumber
        location
        notes
        dateInstalled
        counterInstalled
        parentId
        owner
        createdAt
        updatedAt
        __typename
      }
      tasks {
        nextToken
        __typename
      }
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTaskPogoItemCounter = /* GraphQL */ `
  mutation UpdateTaskPogoItemCounter(
    $input: UpdateTaskPogoItemCounterInput!
    $condition: ModelTaskPogoItemCounterConditionInput
  ) {
    updateTaskPogoItemCounter(input: $input, condition: $condition) {
      id
      name
      counter
      itemId
      item {
        id
        name
        description
        manufacturer
        serialNumber
        partNumber
        location
        notes
        dateInstalled
        counterInstalled
        parentId
        owner
        createdAt
        updatedAt
        __typename
      }
      tasks {
        nextToken
        __typename
      }
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTaskPogoItemCounter = /* GraphQL */ `
  mutation DeleteTaskPogoItemCounter(
    $input: DeleteTaskPogoItemCounterInput!
    $condition: ModelTaskPogoItemCounterConditionInput
  ) {
    deleteTaskPogoItemCounter(input: $input, condition: $condition) {
      id
      name
      counter
      itemId
      item {
        id
        name
        description
        manufacturer
        serialNumber
        partNumber
        location
        notes
        dateInstalled
        counterInstalled
        parentId
        owner
        createdAt
        updatedAt
        __typename
      }
      tasks {
        nextToken
        __typename
      }
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTaskPogoEmailSubscription = /* GraphQL */ `
  mutation CreateTaskPogoEmailSubscription(
    $input: CreateTaskPogoEmailSubscriptionInput!
    $condition: ModelTaskPogoEmailSubscriptionConditionInput
  ) {
    createTaskPogoEmailSubscription(input: $input, condition: $condition) {
      id
      emailType
      email
      name
      sendHourGMT
      itemId
      sendEveryDays
      lastSentDate
      nextSendDate
      unsubscribeKey
      item {
        id
        name
        description
        manufacturer
        serialNumber
        partNumber
        location
        notes
        dateInstalled
        counterInstalled
        parentId
        owner
        createdAt
        updatedAt
        __typename
      }
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTaskPogoEmailSubscription = /* GraphQL */ `
  mutation UpdateTaskPogoEmailSubscription(
    $input: UpdateTaskPogoEmailSubscriptionInput!
    $condition: ModelTaskPogoEmailSubscriptionConditionInput
  ) {
    updateTaskPogoEmailSubscription(input: $input, condition: $condition) {
      id
      emailType
      email
      name
      sendHourGMT
      itemId
      sendEveryDays
      lastSentDate
      nextSendDate
      unsubscribeKey
      item {
        id
        name
        description
        manufacturer
        serialNumber
        partNumber
        location
        notes
        dateInstalled
        counterInstalled
        parentId
        owner
        createdAt
        updatedAt
        __typename
      }
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTaskPogoEmailSubscription = /* GraphQL */ `
  mutation DeleteTaskPogoEmailSubscription(
    $input: DeleteTaskPogoEmailSubscriptionInput!
    $condition: ModelTaskPogoEmailSubscriptionConditionInput
  ) {
    deleteTaskPogoEmailSubscription(input: $input, condition: $condition) {
      id
      emailType
      email
      name
      sendHourGMT
      itemId
      sendEveryDays
      lastSentDate
      nextSendDate
      unsubscribeKey
      item {
        id
        name
        description
        manufacturer
        serialNumber
        partNumber
        location
        notes
        dateInstalled
        counterInstalled
        parentId
        owner
        createdAt
        updatedAt
        __typename
      }
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
