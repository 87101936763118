// Shows a card with a header and a table.
//
// props.header = Text to show at top of the card
// props.footer = Object to render at footer (or null)
// props.columnInfo = [{name: 'Name', 'key': 'KeyName'}].  Info on each column, a title for the top, and field in props.data.map to display.
// props.data = [Dictionary] is data to display.  It's an array of dictionary. will fish out each columnInfo and use the key to access the data from the dictionary.
// props.onClickRow = function to call when a row is clicked
// props.noTitle = "true" if don't want title row

import DataCard from './DataCard';

const InfoCard = (props) => {
  return (
    <DataCard
      header={props.header}
      body=<div className="table-responsive">
        <table className="table table-hover text-nowrap table-striped">
          {
            (props.noTitle !== "true") &&
            <thead>
              <tr>
                {
                  props.columnInfo.map((col, index) => (
                    <th key={col.key} scope="col">{col.name}</th>
                  ))
                }
              </tr>
            </thead>
          }
          <tbody>
            {
              props.data.map((item, index) => (
                <tr key={index} onClick={() => props.onClickRow(item) } >
                  {
                    props.columnInfo.map((col, index) => (
                      <td key={col.key}>
                        <div className="entered-data">
                          {item[col.key]}
                        </div>
                      </td>
                    ))
                  }
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
      footer={props.footer}
    />
  )
}

export default InfoCard;
