// Shows Email Subscriptions for the current user
//
// Properties:
//    item: Item subs belong to

import { useState} from 'react';
import { useStatusMessage } from '../../components';
import Button from 'react-bootstrap/Button';
import * as Database from '../../utilities/Database.mjs';
import { calculateNextSendDate } from '../../fleet-shared/Email.mjs';
import { 
    emailTypeItemStatus, 
    emailSendDaily, 
    emailSendWeekly, 
    emailSendMonthly, 
    emailSendQuarterly, 
    emailSendEverySixMonths,
    emailSendYearly
} from '../../fleet-shared/Email.mjs';


const DeleteButton = ({ deletingId, subId, onDelete }) => (
  <div>
      <i className='p-2 fa fa-trash col-1' aria-hidden='true' onClick={() => onDelete(subId)}></i>
      {(deletingId === subId) && (<span className='p-2'><b>Deleting...</b></span>)}
  </div>
);

const SaveButton = ({ savingId, subId, onSave, showSave, setShowSave }) => (
  <div className='col-4 text-start ml-2'>
    {showSave && <Button onClick={() => onSave(subId, setShowSave)} className="mx-2 col-3 m-2 btn-sm">
        Save
    </Button>}
    {(savingId === subId) && (<span className='p-2'><b>Saving...</b></span>)}
  </div>
);


// A single email-subscripton
const Subscription = ({sub, deletingId, onDelete, savingId, onSave }) => {
  const [showSave, setShowSave] = useState(false);
  const [to, setTo] = useState(sub.email);
  const [frequency, setFrequency] = useState(sub.sendEveryDays);
  const [sendHourGMT, setSendHourGMT] = useState(sub.sendHourGMT);

  const frequencies = [
    {
      name: 'Daily',
      numDays: emailSendDaily
    }, 
    {
      name: 'Weekly',
      numDays: emailSendWeekly
    },
    {
      name: 'Monthly',
      numDays: emailSendMonthly
    },
    {
      name: 'Quarterly',
      numDays: emailSendQuarterly
    },
    {
      name: 'Every 6 months',
      numDays: emailSendEverySixMonths
    },
    {
      name: 'Yearly',
      numDays: emailSendYearly
    }
];

  const handleToChange = (event) => {
    setTo(event.target.value);
    setShowSave(true);
    sub.email = event.target.value;
  }

  const handleFrequencyChange = (event) => {
    setFrequency(event.target.value)
    setShowSave(true);
    sub.sendEveryDays = event.target.value;
  }

  const handleSendHourGMTChange = (event) => {
    setSendHourGMT(event.target.value);
    setShowSave(true);
    sub.sendHourGMT = event.target.value;
  }

  return (
      <div key={sub.id} className='d-flex flex-row align-items-center justify-content-center'>
          <label className='col-2 px-3 text-end'>
              Frequency
          </label>

          <select 
              className='col-1'
              name='Frequency'
              value={frequency} 
              onChange={handleFrequencyChange}
          >

              { frequencies.map((option) => (
                  <option key={option.name} value={option.numDays}>{option.name}</option>
              ))}

          </select> 

          <input 
            className="col-2" 
            name='To' 
            type='text'
            placeholder='email address'
            value={to} 
            onChange={handleToChange}
          />

          <select
              className='col-1'
              name='SendHour'
              value={sendHourGMT}
              onChange={handleSendHourGMTChange}
          >
              { [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23].map ( (v) => (
                 <option key={v} value={v}>{v} GMT</option>
              ))}
          </select>

          <DeleteButton 
            subId={sub.id} 
            deletingId={deletingId}
            onDelete={onDelete}
          />

          <SaveButton
            subId={sub.id} 
            savingId={savingId}
            onSave={onSave}
            showSave={showSave}
            setShowSave={setShowSave}
          />
      </div>
  );

}


export const EmailSubscriptionsCard = ( { user, item } ) => {
    const [deletingId, setDeletingId] = useState(null);
    const [savingId, setSavingId] = useState(null);

    // Loading/error UI
    const [StatusMessage, {showText, showError, hideStatus}] = useStatusMessage();

    async function handleAddSubscription() {
        try {
            // Show adding
            showText('Adding');

            // Add a subscription
            let unsub = crypto.randomUUID();
            let subscription = {
                emailType: emailTypeItemStatus,
                email: user.email,
                name: item.name,
                sendHourGMT: 18,
                itemId: item.id,
                sendEveryDays: emailSendDaily,
                unsubscribeKey: unsub
            }
            subscription.nextSendDate = calculateNextSendDate(subscription);

            let newSub = await Database.createEmailSubscription(subscription);

            // Add to model
            // TODO: Isn't item supposed to be read-only?
            item.emails.items.push(newSub);
    
            // Hide adding ui.
            hideStatus();
        } catch (err) {
            showError(err);
        }
    }


    async function onDelete(subId) {
        try {
            // Show deleting.
            setDeletingId(subId);

            // Delete on Server.
            await Database.deleteEmailSubscription(subId);

            // Update Model
            // TODO: Isn't item supposed to be read-only?
            item.emails.items = item.emails.items.filter( email => (email.id !== subId));

            setDeletingId(null);
        } catch(err) {
            // TODO: Next to delete button?
            showError(err);
        }
    }

    async function onSave(subId, setShowSave) {
        try {
            // Show deleting.
            setSavingId(subId);

            // Find the subscription
            let sub = item.emails.items.find((e) => (e.id === subId));
            if (!sub) {
                // should never happen...
                showError('Can\'t find id...');
                return;
            }

            // Update on Server.
            let update = await Database.updateEmailSubscription(sub);

            // Update Model
            // TODO: Isn't item supposed to be read-only?
            item.emails.items = item.emails.items.map( email => (email.id !== subId) ? email : update);

            setSavingId(null);
            setShowSave(false);
        } catch(err) {
            // TODO: Next to delete button?
            showError(err);
        }
    }

    // No item, no UI...
    if (!item) {
        return null;
    }

    return (
        <div className="container pt-2">
            <div className="card">
                <div className="card-header text-center">
                  <h5>
                    <span className="text-center">
                      <strong>Manage Subscriptions</strong>
                    </span>
                  </h5>
                </div>

                <div className="container text-center">
                    { item.emails.items.map( (sub) => (
                        <Subscription key={sub.id} sub={sub} deletingId={deletingId} onDelete={onDelete} savingId={savingId} onSave={onSave} />
                    ))}
                    <Button onClick={handleAddSubscription} className="mx-2 col-3 m-2">
                      Add
                    </Button>
                    {StatusMessage()}
                </div>
            </div>
        </div>
    )
}

