/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTaskPogoItem = /* GraphQL */ `
  query GetTaskPogoItem($id: ID!) {
    getTaskPogoItem(id: $id) {
      id
      name
      description
      manufacturer
      serialNumber
      partNumber
      location
      notes
      dateInstalled
      counterInstalled
      parentId
      itemCounter {
        nextToken
        __typename
      }
      tasks {
        nextToken
        __typename
      }
      completedTasks {
        nextToken
        __typename
      }
      parentItem {
        id
        name
        description
        manufacturer
        serialNumber
        partNumber
        location
        notes
        dateInstalled
        counterInstalled
        parentId
        owner
        createdAt
        updatedAt
        __typename
      }
      childItems {
        nextToken
        __typename
      }
      emails {
        nextToken
        __typename
      }
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTaskPogoItems = /* GraphQL */ `
  query ListTaskPogoItems(
    $filter: ModelTaskPogoItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskPogoItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        manufacturer
        serialNumber
        partNumber
        location
        notes
        dateInstalled
        counterInstalled
        parentId
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const itemsByParentId = /* GraphQL */ `
  query ItemsByParentId(
    $parentId: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskPogoItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemsByParentId(
      parentId: $parentId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        manufacturer
        serialNumber
        partNumber
        location
        notes
        dateInstalled
        counterInstalled
        parentId
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTaskPogoTask = /* GraphQL */ `
  query GetTaskPogoTask($id: ID!) {
    getTaskPogoTask(id: $id) {
      id
      name
      assignedEmail
      priority
      rank
      cost
      itemId
      item {
        id
        name
        description
        manufacturer
        serialNumber
        partNumber
        location
        notes
        dateInstalled
        counterInstalled
        parentId
        owner
        createdAt
        updatedAt
        __typename
      }
      description
      notes
      dueCounter
      dueDate
      counterInterval
      daysInterval
      itemCounterId
      itemCounter {
        id
        name
        counter
        itemId
        owner
        createdAt
        updatedAt
        __typename
      }
      completedTasks {
        nextToken
        __typename
      }
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTaskPogoTasks = /* GraphQL */ `
  query ListTaskPogoTasks(
    $filter: ModelTaskPogoTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskPogoTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        assignedEmail
        priority
        rank
        cost
        itemId
        description
        notes
        dueCounter
        dueDate
        counterInterval
        daysInterval
        itemCounterId
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const tasksByItemIdAndName = /* GraphQL */ `
  query TasksByItemIdAndName(
    $itemId: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskPogoTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tasksByItemIdAndName(
      itemId: $itemId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        assignedEmail
        priority
        rank
        cost
        itemId
        description
        notes
        dueCounter
        dueDate
        counterInterval
        daysInterval
        itemCounterId
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const tasksByItemIdAndDueDate = /* GraphQL */ `
  query TasksByItemIdAndDueDate(
    $itemId: ID!
    $dueDatePriorityAssignedEmail: ModelTaskPogoTaskByItemIdAndDueDateCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskPogoTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tasksByItemIdAndDueDate(
      itemId: $itemId
      dueDatePriorityAssignedEmail: $dueDatePriorityAssignedEmail
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        assignedEmail
        priority
        rank
        cost
        itemId
        description
        notes
        dueCounter
        dueDate
        counterInterval
        daysInterval
        itemCounterId
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const tasksByItemIdAndAssigned = /* GraphQL */ `
  query TasksByItemIdAndAssigned(
    $itemId: ID!
    $assignedEmailPriority: ModelTaskPogoTaskByItemIdAndAssignedEmailCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskPogoTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tasksByItemIdAndAssigned(
      itemId: $itemId
      assignedEmailPriority: $assignedEmailPriority
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        assignedEmail
        priority
        rank
        cost
        itemId
        description
        notes
        dueCounter
        dueDate
        counterInterval
        daysInterval
        itemCounterId
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const tasksByItemIdAndPriority = /* GraphQL */ `
  query TasksByItemIdAndPriority(
    $itemId: ID!
    $priorityAssignedEmail: ModelTaskPogoTaskByItemIdAndPriorityCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskPogoTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tasksByItemIdAndPriority(
      itemId: $itemId
      priorityAssignedEmail: $priorityAssignedEmail
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        assignedEmail
        priority
        rank
        cost
        itemId
        description
        notes
        dueCounter
        dueDate
        counterInterval
        daysInterval
        itemCounterId
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const tasksByItemCounterId = /* GraphQL */ `
  query TasksByItemCounterId(
    $itemCounterId: ID!
    $dueCounter: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskPogoTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tasksByItemCounterId(
      itemCounterId: $itemCounterId
      dueCounter: $dueCounter
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        assignedEmail
        priority
        rank
        cost
        itemId
        description
        notes
        dueCounter
        dueDate
        counterInterval
        daysInterval
        itemCounterId
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTaskPogoCompletedTask = /* GraphQL */ `
  query GetTaskPogoCompletedTask($id: ID!) {
    getTaskPogoCompletedTask(id: $id) {
      id
      name
      cost
      fixByEmail
      itemId
      date
      item {
        id
        name
        description
        manufacturer
        serialNumber
        partNumber
        location
        notes
        dateInstalled
        counterInstalled
        parentId
        owner
        createdAt
        updatedAt
        __typename
      }
      itemCounterId
      itemCounter {
        id
        name
        counter
        itemId
        owner
        createdAt
        updatedAt
        __typename
      }
      taskId
      Task {
        id
        name
        assignedEmail
        priority
        rank
        cost
        itemId
        description
        notes
        dueCounter
        dueDate
        counterInterval
        daysInterval
        itemCounterId
        owner
        createdAt
        updatedAt
        __typename
      }
      description
      taskNotes
      dueCounter
      dueDate
      counterInterval
      daysInterval
      notes
      counter
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTaskPogoCompletedTasks = /* GraphQL */ `
  query ListTaskPogoCompletedTasks(
    $filter: ModelTaskPogoCompletedTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskPogoCompletedTasks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        cost
        fixByEmail
        itemId
        date
        itemCounterId
        taskId
        description
        taskNotes
        dueCounter
        dueDate
        counterInterval
        daysInterval
        notes
        counter
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const completedTasksByItemId = /* GraphQL */ `
  query CompletedTasksByItemId(
    $itemId: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskPogoCompletedTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    completedTasksByItemId(
      itemId: $itemId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        cost
        fixByEmail
        itemId
        date
        itemCounterId
        taskId
        description
        taskNotes
        dueCounter
        dueDate
        counterInterval
        daysInterval
        notes
        counter
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const completedTasksByTaskId = /* GraphQL */ `
  query CompletedTasksByTaskId(
    $taskId: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskPogoCompletedTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    completedTasksByTaskId(
      taskId: $taskId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        cost
        fixByEmail
        itemId
        date
        itemCounterId
        taskId
        description
        taskNotes
        dueCounter
        dueDate
        counterInterval
        daysInterval
        notes
        counter
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTaskPogoItemCounter = /* GraphQL */ `
  query GetTaskPogoItemCounter($id: ID!) {
    getTaskPogoItemCounter(id: $id) {
      id
      name
      counter
      itemId
      item {
        id
        name
        description
        manufacturer
        serialNumber
        partNumber
        location
        notes
        dateInstalled
        counterInstalled
        parentId
        owner
        createdAt
        updatedAt
        __typename
      }
      tasks {
        nextToken
        __typename
      }
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTaskPogoItemCounters = /* GraphQL */ `
  query ListTaskPogoItemCounters(
    $filter: ModelTaskPogoItemCounterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskPogoItemCounters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        counter
        itemId
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const itemCounterByItemId = /* GraphQL */ `
  query ItemCounterByItemId(
    $itemId: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskPogoItemCounterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemCounterByItemId(
      itemId: $itemId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        counter
        itemId
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTaskPogoEmailSubscription = /* GraphQL */ `
  query GetTaskPogoEmailSubscription($id: ID!) {
    getTaskPogoEmailSubscription(id: $id) {
      id
      emailType
      email
      name
      sendHourGMT
      itemId
      sendEveryDays
      lastSentDate
      nextSendDate
      unsubscribeKey
      item {
        id
        name
        description
        manufacturer
        serialNumber
        partNumber
        location
        notes
        dateInstalled
        counterInstalled
        parentId
        owner
        createdAt
        updatedAt
        __typename
      }
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTaskPogoEmailSubscriptions = /* GraphQL */ `
  query ListTaskPogoEmailSubscriptions(
    $filter: ModelTaskPogoEmailSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskPogoEmailSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        emailType
        email
        name
        sendHourGMT
        itemId
        sendEveryDays
        lastSentDate
        nextSendDate
        unsubscribeKey
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const taskPogoEmailSubscriptionsByItemIdAndName = /* GraphQL */ `
  query TaskPogoEmailSubscriptionsByItemIdAndName(
    $itemId: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskPogoEmailSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    taskPogoEmailSubscriptionsByItemIdAndName(
      itemId: $itemId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        emailType
        email
        name
        sendHourGMT
        itemId
        sendEveryDays
        lastSentDate
        nextSendDate
        unsubscribeKey
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
