// prop.heading is the heading for the HourSummary.
// props.hours is the hours being shown
// props.onClick is what to call when clicked.

function HourSummary(props) {
  return (
    <div className="col-xl-3 col-sm-6 col-12 mb-4" onClick={props.onClick}>
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between px-md-1">
            <div className="align-self-center">
              <i className="fas fa-3x fa-clock text-primary"></i>
            </div>
            <div className="text-end">
              <h5>{props.heading}</h5>
                <div className="d-flex justify-content-center align-items-center">
                  <div className="px-1 entered-data">{props.hours}</div>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <div className="text-muted">Click to edit.</div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HourSummary;
