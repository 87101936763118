// Header for a TaskTable or a single task.


/*
    <thead>
      <tr>
        <th key='statusHdg'            scope='col' className='fw-bold'>
          Status
          </th>
        <th key='nameHdg'              scope='col' className='fw-bold'>
          <div>Name</div>
          <div>Description</div>
          <div>Notes</div>
        </th>
        <th key='dueCounterHdg'        scope='col' className='fw-bold'>
          <div>Due (counter)</div>
          <div>Counter Based On</div>
          <div>Interval</div>
        </th>
        <th key='dueDateHdg'           scope='col' className='fw-bold'>
          <div>Due (Date)</div>
          <div>Days Interval</div>
        </th>
      </tr>
    </thead>
*/

const TaskListHeaderItem = ({ items, className }) => {
  return (
      <div className={className}>
        {items.map((item, index) => {
          return (
            <div key={index}>{item}</div>
          );          
        })}
      </div>
  );
}

// TODO: This guys vertical lines don't line up with the table below always??

export const TaskListHeader = () => {
  return (
    <div className='tasks-header'>
      <TaskListHeaderItem items={['Status']} className='tasks-row-item' />
      <TaskListHeaderItem items={['Name', 'Description', 'Notes']} className='tasks-row-item' />
      <TaskListHeaderItem items={['Due', 'Counter']} className='tasks-row-item' />
      <TaskListHeaderItem items={['Due', 'Date']} className='tasks-row-item' />
      <TaskListHeaderItem items={['Assigned', 'Priority']} className='tasks-row-item' />
    </div>
  );    
}
