// Component for editting an entry in the CompletedTasks table.
//
//   entry - CompletedTask to edit
//   item - Item task belongs to
//   handleSave - async function to save the task to the DB
//   handleDelete - async function to delete the task from the DB
//   children - Optional extra HTML to inject after the edit form.

import { DataCard, EditForm, Attachments } from '../../components';
import { completedTaskFields } from '../../fleet-shared/CompletedTaskFields.mjs';
import { urlForLogbookEntryStorage } from '../../fleet-shared/Urls.mjs';
import { buildFieldsForItem } from '../../fleet-shared/Common.mjs'


export const LogbookEditEntry = ({ entry, item, handleSave, handleDelete, children }) => {
  return (entry !== null) && (
    <DataCard
      header=<strong>Edit Logbook Entry</strong>
      body=<EditForm
              item={entry}
              fields={buildFieldsForItem(completedTaskFields, item) }
              saveItem={handleSave}
              handleSecondaryButton={handleDelete}
              secondaryButtonText='Delete'
              secondaryButtonVariant='danger'
            >
              <Attachments bucket={urlForLogbookEntryStorage(item.id, entry.id)} />
              {children}
            </EditForm>
    />
  );
}