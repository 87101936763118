// Attachments support
//    bucket - is the bucket to find the attachments
//    hideLoading - hide the loading indicator

import { StorageImage, StorageManager } from '@aws-amplify/ui-react-storage';
import { useState, useEffect, useCallback, useRef } from 'react';
import { DataCard, useStatusMessage } from '.';
import { CardHeader } from './CardHeader';
import { storageGet, storageList, storageDelete, addNameToStorage, processStorageList } from '../utilities/Database.mjs';
import { itemThumbnailPrefix } from '../fleet-shared/Urls';
import '@aws-amplify/ui-react/styles.css';

// TODO: More types
const imageExtensions = new Set(['jpg', 'jpeg', 'png', 'gif', 'bmp']);

function isImage(filename) {
  let extension = filename.split('.').slice(-1)[0].toLowerCase();
  return imageExtensions.has(extension);
}

function seperatePictureFromFiles(files) {
  var pictures = [];
  var data = [];

  for (var i=0; i < files.length; i++) {
    if (isImage(files[i].key)) {
      pictures.push(files[i]);
    } else {
      data.push(files[i]);
    }
  }

  return {
    pictures: pictures,
    files: data
  }
}


// List of attached files.
const FileList = ( {files, showText, showError, onClickDelete } ) => {
  // User clicked a file, navigate to it.
  async function onClickRow(file) {
    try {
      showText('Redirecting');
      let url = await storageGet(file.key);
      showText('');
      window.location.href = url.url.href;
    }
    catch (err) {
      showError(err);
    }
  }

  // Return a row per file.
  return files.map((file) => (
    <tr key={file.key} className="entered-data">
      <td>
        <span className='col-6' onClick={() => onClickRow(file)}>
          <i className='fa fa-paperclip col-1' aria-hidden='true'></i>
          {file.name}
        </span>
        <i className='fa fa-trash col-1' aria-hidden='true' onClick={() => onClickDelete(file)}></i>
      </td>
    </tr>
  ));
}


// A list of photos.
const PhotoList = ({pictures, onClickDelete, showText, showError}) => {
  async function onClickPicture(picture) {
    showText('Redirecting');
    let url = await storageGet(picture.key);
    showText('');
    window.location.href = url.url.href;
  }

  return pictures && pictures.map( (picture) => {
    return (
      <tr key={picture.key}>
        <td>
          <span className='col-6' onClick={() => onClickPicture(picture)}>
            <StorageImage 
                alt={picture.name} 
                imgKey={picture.key} 
                accessLevel='private'
                onStorageGetError={showError}
            />
          </span>
          <span className='col-1'>
            <i className='fa fa-trash col-1' aria-hidden='true' onClick={() => onClickDelete(picture)}></i>
          </span>
        </td>
      </tr>
    );
  })
}



const Body = ({ pictures, files, onClickDelete, bucket, showText, showError, hideStatus, setPictures, setFiles }) => {
  function uploadError(error, file) {
    showError(error);
  }

  function uploadStarted(file) {
    // console.log(`uploadedStarted: ${JSON.stringify(file)}`);

    showText(`Uploading ${file.key}`);
  }

  function uploadSuccess(file) {
    // console.log(`Upload success: ${JSON.stringify(file)}`);
    
    hideStatus();

    // Add the name property to the file.
    addNameToStorage(file, bucket);

    // Update the model.
    var newAll = [...files];
    newAll = newAll.concat([...pictures]);

    // If file was already in the list, we just refreshed it (maybe new contents), nothing to do.
    if (newAll.reduce((old, current) => old || (current.name===file.name), false)) {
      return;
    }

    // Otherwise, add the file to the UI.
    newAll.push(file);
    let pictureFiles = seperatePictureFromFiles(newAll);

    setPictures(pictureFiles.pictures);
    setFiles(pictureFiles.files);
  }

  // For some reason StorageManager doesn't honor the path field anymore,
  // so I added a processFile to add the bucket in (I had it in path={bucket}).
  function processFile(keyFile) {
    return { 
      key: `${bucket}${keyFile.key}`,
      file: keyFile.file
    }
  }

// console.log(`Showing StorageManager bucket: ${bucket}`);
  // TODO: Max file size, etc.?
  return (
    <div className="table-responsive">

    <table className="table table-hover text-nowrap table-striped">
      <tbody>
        <PhotoList 
          pictures={pictures} 
          onClickDelete={onClickDelete}
          showText={showText}
          showError={showError}
        />
        <FileList files={files} showText={showText} showError={showError} onClickDelete={onClickDelete} />
      </tbody>
    </table>

    <StorageManager
      accessLevel='private'
      maxFileCount={10}
      maxFileSize={10000000}
      onUploadStart={uploadStarted}
      onUploadSuccess={uploadSuccess}
      onUploadError={uploadError}
      processFile={processFile}
      isResumable
    />

  </div>
  );
}



// TODO: Max file size.
const Attachments = ({bucket, hideLoading}) => {
  const isMounted = useRef(false);

  const [files, setFiles] = useState([]);
  const [pictures, setPictures] = useState([]);

  const [StatusMessage, {showText, showError, showLoading, hideStatus}] = useStatusMessage();

  // *** Initial Loading ***
  useEffect(() => {
    isMounted.current = true;

    showLoading();

    async function fetchAttachments() {
      try {
        let results = await storageList(bucket);

        if (!isMounted) { 
          console.log(`**Waste of time, unmounted: bucket`)
          return 
        };

        // Remove thumbnail images.
        let prefix = itemThumbnailPrefix(bucket); // `${bucket}thumbnail`;
        results = results.filter((item) => !item.key.startsWith(prefix));

        // Process the list to add names
        let data = processStorageList(results, bucket);

        // Update UI.
        hideStatus();

        let pictureFiles = seperatePictureFromFiles(data.files);
        setPictures(pictureFiles.pictures);
        setFiles(pictureFiles.files);
      } catch(err) {
        if (!isMounted) { return };
        showError(err);
      }
    }

    fetchAttachments();

    return () => {
      isMounted.current = true;
    }
  }, [bucket, hideStatus, showError, showLoading]);


  /**** Handlers ***/
  const onClickDelete = useCallback((file) => {
    showText(`Deleting ${file.name}`);

    storageDelete(file.key)
      .then(({ results }) => {
        if (!isMounted.current) {
          return;
        }
        hideStatus();
        let newFiles = files.filter( (f) => { 
          return f.key !== file.key;
        });
        setFiles(newFiles);
        let newPictures = pictures.filter( (p) => {
          return p.key !== file.key;
        });
        setPictures(newPictures);
      })
      .catch((err) => {
        showError(err);
      });
  }, [files, hideStatus, pictures, showError, showText]);

  return (
    <div>
      {(!hideLoading) && StatusMessage()}
      <DataCard
        header=<CardHeader 
          text='Attached Files' 
        />
        body=<Body 
          bucket={bucket} 
          pictures={pictures} 
          files={files} 
          onClickDelete={onClickDelete} 
          showText={showText}
          showError={showError}
          hideStatus={hideStatus}
          setPictures={setPictures}
          setFiles={setFiles}
        />
      />
    </div>
  );
}

Attachments.defaultProps = {
    hideLoading: false
};


export default Attachments;