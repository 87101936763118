// A component that renders a list of tasks.
// fetchItems is a function that fetches the next page of tasks. You can use useTaskQuery to get this function.
// onTaskClicked is a callback function that is called when a task is clicked. It is passed the task that was clicked.
// If onTaskClicked is not supplied, the task is navigated to when the user clicks it.

import { useCallback } from 'react';
import { TaskListHeader} from './TaskListHeader';
import { TaskLine } from './TaskLine';
import { useMyInfiniteScroll } from '../../components/MyInfiniteScroll';

export const TaskTable = ({ fetchItems, onTaskClicked }) => {
  const renderChildren = useCallback((items) => {
    return (
      <div className='tasks-wrapper'>
        { items.map((task, index) => (
          <TaskLine 
            task={task} 
            key={index} 
            onTaskClicked={onTaskClicked}
          />
        ))}
      </div>
    );
  }, [onTaskClicked]);

  const endMessage = () => {
    return (
      <div className='tasks-end'><b>&#8212;End of tasks&#8212;</b></div>
    );
  }
  
  const { Control } = useMyInfiniteScroll(
    renderChildren, 
    fetchItems,
    endMessage
  );

  return (
    <div className='tasks'>
      <TaskListHeader />
      <Control />
    </div>
  );
}
