// RadioSelection component
//
// options - [{value:'x', text:'Text for x.', children:children}]
//           children is optional HTML to insert after
// option - a useState getter for currently selected option (value from options)
// setOption - a useState setter for the currently selected option.

const RadioButton = ({ selectedOption, onChange, thisOption, children, label }) => (
  <>
    <label>
      <input
        type='radio'
        value={thisOption}
        checked={selectedOption === thisOption }
        onChange={e => onChange(e)}
       />
      {label}
    </label>
  </>
);


// option are an array of: { html, value }
export const RadioSelection = ({ options, option, setOption, children }) => {
  // Update state when change the LogbookUpdateTask selection.
  function optionChanged(event) {
    let value = event.target.value;
    setOption(value);
  }

  return options.map ( (o) => 
    <div key={o.value} className='text-center'>
      <RadioButton 
        label={o.text} 
        thisOption={o.value}  
        selectedOption={option} 
        onChange={optionChanged}
      />
      {o.children}
    </div>
  )
}