// Status message - Defaults to a Loading spinner. Can be an error, etc.
// Optional parameters:
//      isVisible - Whether to show or not, defaults to true.
//      isSpinnerVisible - Wheter to show the spiner, defaults to true.
//      isActive - whether to spin or not, defaults to false.
//      text - text to display. Defaults to 'Loading...'
//      textStyle - text-primary, text-danger, etc. Defaults to text-primary.
//      showCancelButton - Whether or not to show a cancel button, defaults to false.
//      onHide - Called when Cancel button is pressed, parent should hide/cleanup/etc.

import { useState, useCallback } from 'react';
import { extractErrorText } from '../fleet-shared/Common.mjs';

const CancelButton = ({showCancelButton, onHide}) => {
    if (!showCancelButton) {
        return null;
    }

    return (
        <i className='fa fa-trash' aria-hidden='true' onClick={onHide}></i>
    );
}

export const StatusMessage = ({ isVisible, isSpinnerVisible, isActive, text, textStyle, showCancelButton, onHide }) => {
    const Spinner = () => {
        if (isSpinnerVisible) {
            return (
                <i className={`fa fa-spinner ${spin}`} aria-hidden='true'></i>
            );
        } else {
            return null;
        }
    }

    if (!isVisible) {
        return null;
    }

    const spin = isActive ? 'fa-spin' : '';

    return (
        <div className={`text-center ${textStyle}`}>
            <Spinner />
            {text ? <span className='p-1'>{text}</span> : null}
            <CancelButton showCancelButton={showCancelButton} onHide={onHide} />
        </div>
    );
}

StatusMessage.defaultProps = {
    isVisible: true,
    isSpinnerVisible: true,
    isActive: false,
    text: 'Loading...',
    textStyle: 'text-primary',
    showCancelButton: false
};

// Same as a StatusMessage with showLoading() called.
export const LoadingMessage = () => {
    return (
        <StatusMessage 
            text='Loading...'
            textStyle='text-primary'
            isVisible={true}
            isSpinnerVisible={true}
            isActive={true}
        />
    );
}


// Hook for using StatusMessage, provide all the state and functions to set them.
// Returns [StatusMessage, setStatusMessage, hideStatusMessage]:
//      StatusMessage - component to render the StatusMessage
//      setStatusMessage - function to update StatusMessage: (text, isLoading, isError)
//      hideStatus - function to hide the StatusMessage
export const useStatusMessage = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [showStatus, setShowStatus] = useState(true);
    const [showSpinner, setShowSpinner] = useState(false);
    const [statusMessage, setStatusMessage] = useState('');
    const [statusTextStyle, setStatusTextStyle] = useState('text-primary');

    const setStatus = useCallback((text, isLoading, isError) => {
        setIsLoading(isLoading);
        setShowSpinner(!isError);
        setStatusMessage(text);

        if (isError) {
          setStatusTextStyle('text-danger');
        } else {
          setStatusTextStyle('text-primary');
        }
        setShowStatus(true);
    }, []);

    const hideStatus = useCallback(() => {
        setIsLoading(false);
        setShowSpinner(false);
        setShowStatus(false);
    }, []);

    const showLoading = useCallback((message = 'Loading...') => {
        setIsLoading(true);
        setShowSpinner(true);
        setStatusMessage(message);
        setStatusTextStyle('text-primary');
        setShowStatus(true);
    }, []);

    const showText = useCallback((text) => {
        setIsLoading(false);
        setShowSpinner(false);
        setStatusMessage(text);
        setStatusTextStyle('text-primary');
        setShowStatus(true);
    }, []);

    const showError = useCallback((err) => {
        var errString = extractErrorText(err);

        console.error(errString);

        setStatusMessage(errString);
        setIsLoading(false);
        setShowSpinner(false);
        setStatusTextStyle('text-danger');
        setShowStatus(true);
    }, []);


    const UI = useCallback(() => {
        return (
            <StatusMessage 
              text={statusMessage}
              textStyle={statusTextStyle}
              isVisible={showStatus} 
              isSpinnerVisible={showSpinner}
              isActive={isLoading} 
            />
        )
    }, [statusMessage, statusTextStyle, showStatus, showSpinner, isLoading]);

    return [UI, { 
        showLoading: showLoading,
        showText: showText,
        showError: showError,
        setStatus: setStatus, 
        hideStatus: hideStatus,
        isLoading: isLoading,
        setIsLoading: setIsLoading,
        showStatus: showStatus,
        setShowStatus: setShowStatus,
        showSpinner: showSpinner,
        setShowSpinner: setShowSpinner,
        statusMessage: statusMessage, 
        setStatusMessage: setStatusMessage,
        statusTextStyle: statusTextStyle,
        setStatusTextStyle: setStatusTextStyle
    }];
};

export default useStatusMessage;
