// User profile page

const Profile = () => {
  return (
    <div className="text-center">
      <div>
        Profile.
      </div>
    </div>
  )
}  

export default Profile;

