// Header navigation for the app. Includes a sign out button, and various lins at top.
// props.user is the signed in user (or null, if none)
// props.signOut is the function to call when sign out button is hit.

import React from "react";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { Spinner } from "./Spinner";
import * as Urls from '../fleet-shared/Urls.mjs';

const SignedInMenu = ({user, signOut}) => {
  function signOutClicked() {
    signOut();
  }

  return (
    <div className="dropdown">
      <button
        className="btn btn-primary dropdown-toggle m-2"
        type="button"
        id="dropdownMenuButton"
        data-mdb-toggle="dropdown"
        aria-expanded="false"
      >
        <i className="pe-2 fa-solid fa-user"></i>
      </button>
      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <li>
          <Link className='dropdown-item' to={Urls.urlForProfile()}>Profile</Link>
        </li>
        <li>
          <Link className='dropdown-item' to={Urls.urlForChangePassword()}>Change Password</Link>
        </li>
        <li>
          <div className='dropdown-item'>
            <button className="btn btn-danger" type="button" id="signOut" onClick={signOutClicked}>Sign Out</button>
          </div>
        </li>
      </ul>
    </div>
  ); 
};

const SignedOutMenu = () => {
  const navigate = useNavigate();

  function signInClicked() {
    navigate(Urls.urlForAllItems())
  }

  return (
    <div>
      <button className="btn btn-primary" type="button" id="signIn" onClick={signInClicked}>
        <i className="pe-2 fa-solid fa-user"></i>
        Sign In&#47;Up
      </button>
    </div>
  )
};

const ProfileButton = ({user, isInitializing, signOut}) => {
  if (isInitializing) {
    return (
      <Spinner />
    );
  } else if (user != null) {
    return (
      <SignedInMenu user={user} signOut={signOut}/>
    );
  } else {
    return (
      <SignedOutMenu/>
    );
  }
}


function Navigation(props) {
  const location = useLocation();

  return (
    <div className='container'>
      <div className="navbar">
        <div className="navbar-left">
          <a className="navbar-brand" href="/">
            <img src="/logo192.png" className='logo m-2' width="32" height="32" alt="Logo"/>
            TaskPogo
          </a>
        </div>
        <div className="navbar-center">
          <Link to='/' className={`nav-link ${
            location.pathname === "/" ? "active text-dark border-bottom border-primary" : "text-primary" 
          }`}>
            Home
          </Link>
          <Link to={Urls.urlForAllItems()} className={`nav-link ${
            Urls.isUrlUnderAllItems(location.pathname) ? "active text-dark border-bottom border-primary" : "text-primary"
          }`} >
            Items
          </Link>

          <Link to={Urls.urlForSupport()} className={`nav-link ${
            Urls.isUrlForSupport(location.pathname) ? "active text-dark border-bottom border-primary" : "text-primary" 
          }`} >
            Support
          </Link>
        </div>
        <div className="navbar-right">
          <ProfileButton user={props.user} isInitializing={props.isInitializing} signOut={props.signOut}/>
        </div>
      </div>
    </div>
  );
}

export default Navigation;