import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import { Amplify } from 'aws-amplify';
import amplifyconfig from './amplifyconfiguration.json';
Amplify.configure(amplifyconfig);

ReactDOM.render((
      <App />
    ), document.getElementById('root'));

