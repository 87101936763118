import React from 'react';
import { AccountSettings, Card } from '@aws-amplify/ui-react';
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
  const navigate = useNavigate();

  const handleSuccess = () => {
    navigate('/profile');
  }
  
  return (
    <div className="h-200 d-flex align-items-center justify-content-center">
      <Card>
        <AccountSettings.ChangePassword onSuccess={handleSuccess}/>
      </Card>
    </div>
  );
}

export default ChangePassword;
