// These are the fields in an "item" in the database.
// Name is a name to show when editing in the UI
// Key is the key in a map for this field
// Type is the type of the item (used by EditObject to do the right thing for this field)
//    "button"
//    "checkbox"
//    "color"
//    "date"
//    "datetime-local"
//    "email"
//    "file"
//    "hidden"
//    "image"
//    "month"
//    "number"
//    "password"
//    "radio"
//    "range"
//    "reset"
//    "search"
//    "submit"
//    "tel"
//    "text"
//    "time"
//    "url"
//    "week"

export const itemFields = [
  {name: "Name",              key: "name",          type: "text"},
  {name: "Manufacturer",      key: "manufacturer",  type: "text"},
  {name: "Description",       key: "description",   type: "text"},
  {name: "Serial Number",     key: "serialNumber",  type: "text"},
  {name: "Part Number",       key: "partNumber",    type: "text"},
  {name: "Location",          key: "location",      type: "text"},
  {name: "Notes",             key: "notes",         type: "text"},
  {name: "Date Installed",    key: "dateInstalled", type: "date"},
  {name: "Counter Installed", key: "counterInstalled",type: "number"},
  // "itemhoursId",
  // "pictures",
];


