// Text displaying an error. Shown centered in an error color and <h3>.
//   props.text - text to show.
// TODO: If error is long, make it multi-line.
const ErrorText = ({text}) => {
  if (text) {
    return ( 
      <div className="text-center text-danger">
        <h3>{text}</h3>
      </div>
    );
  }
  return null;
}

export default ErrorText;