import React, { useCallback } from 'react';
import { Thumbnail, ThumbnailSmall } from '../item/Thumbnail';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import { urlForItem } from '../../fleet-shared/Urls';
import { TaskSummary } from './TaskSummary';

// UI for an individual item.
export const ItemRow = ({ identityId, itemId, name }) => {
  let navigate = useNavigate();

  const onClickRow = useCallback(() => {
    let itemUrl = urlForItem(itemId);
    navigate(itemUrl);
  }, [itemId, navigate]);

  return (
    <tr onClick={onClickRow}>
      <td className='col-2'>
      </td>

      <td align='right' className='col-1'>
        <Thumbnail identityId={identityId} itemId={itemId} size={ThumbnailSmall} />
      </td>

      <td align='left' className='col-2 entered-data'>
        {name}
      </td>

      <td align='left' className='col-3'>
        <TaskSummary itemId={itemId} />
      </td>

      <td align='right' className='col-2'>
        <Button variant='primary' onClick={onClickRow}>
          Details
        </Button>
      </td>

      <td className='col-2'>
      </td>

    </tr>
  );
};
