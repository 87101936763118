// A component that renders a list of tasks.

import { useCallback, useState, useRef } from 'react';
import { addBucketNameToTasks, graphQLSoon, soonHours } from '../../fleet-shared/TaskBuckets.mjs';
// import { bucketNameAll, bucketNameSoon, bucketNameOverdue } from '../../fleet-shared/Urls.mjs';
import { client } from '../../utilities/Database.mjs';
import { SortOverdue, SortAssigned, SortPriority } from './Tasks.jsx';

// These are the fields I want back for a task.
const taskFields = `
  id
  name
  assignedEmail
  priority
  rank
  cost
  itemId
  description
  notes
  dueCounter
  dueDate
  counterInterval
  daysInterval
  itemCounter {
    counter
    name
  }
`;

// This is the query for the parent item, which includes the counters and their tasks.
const getTaskPogoItem = /* GraphQL */ `
  getTaskPogoItem(id: $itemId) {
    id
    name
    parentId
    itemCounter {
      items {
        id
        name
        counter
        tasks {
          items {
            ${taskFields}
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

// This is the query for the tasks by due date.
// const tasksByItemIdAndDueDate = /* GraphQL */ `
//   tasksByItemIdAndDueDate(
//     itemId: $itemId
//     dueDate: $dueDate
//     sortDirection: $sortDirection
//     filter: $filter
//     limit: $limit
//   ) {
//     items {
//       ${taskFields}
//     }
//     nextToken
//   }
// `;
const tasksByItemIdAndDueDate = /* GraphQL */ `
  tasksByItemIdAndDueDate(
    itemId: $itemId
    sortDirection: $sortDirection
    filter: $filter
  ) {
    items {
      ${taskFields}
    }
    nextToken
  }
`;


// This is the query for the tasks by priority.
const tasksByItemIdAndPriority = /* GraphQL */ `
  tasksByItemIdAndPriority(
    itemId: $itemId
    priorityAssignedEmail: $priorityAssignedEmail
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
) {
    items {
      ${taskFields}
    }
    nextToken
  }
`;

// This is the query for the tasks by assigned.
const tasksByItemIdAndAssigned = /* GraphQL */ `
  tasksByItemIdAndAssigned(
    itemId: $itemId
    assignedEmailPriority: $assignedEmailPriority
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
) {
    items {
      ${taskFields}
    }
    nextToken
  }
`;


// The initial query when looking for overdue/soon items. I query the item and overdue/soon tasks based on date (earlier than now or coming up soon).
// I can then cursor through the tasks by due date and look through the item's counters for more tasks.
const tasksByDateQuery = /* GraphQL */ `
  query TasksByItemIdAndDueDate(
    $itemId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTaskPogoTaskFilterInput
  ) {
    ${getTaskPogoItem}
    ${tasksByItemIdAndDueDate}
  }
`;

// The initial query when sorting by priority
const tasksByPriorityQuery = /* GraphQL */ `
  query TasksByItemIdAndPriority(
    $itemId: ID!
    $priorityAssignedEmail: ModelTaskPogoTaskByItemIdAndPriorityCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskPogoTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ${getTaskPogoItem}
    ${tasksByItemIdAndPriority}
  }
`;

// The initial query when sorting by assigned to
const tasksByAssignedQuery = /* GraphQL */ `
  query TasksByItemIdAndAssigned(
    $itemId: ID!
    $assignedEmailPriority: ModelTaskPogoTaskByItemIdAndAssignedEmailCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskPogoTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ${getTaskPogoItem}
    ${tasksByItemIdAndAssigned}
  }
`;

// Querying for more overdue items by date with a nextToken.
const moreTasksByDateQuery = /* GraphQL */ `
  query MoreTasksByDate(
    $itemId: ID!
    $dueDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskPogoTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ${tasksByItemIdAndDueDate}
  }
`;

// Querying for more overdue items by priority with a nextToken.
const moreTasksByPriorityQuery = /* GraphQL */ `
  query MoreTasksByPriority(
    $itemId: ID!
    $priorityAssignedEmail: ModelTaskPogoTaskByItemIdAndPriorityCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskPogoTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ${tasksByItemIdAndPriority}
  }
`;

// Querying for more overdue items by assigned with a nextToken.
const moreTasksByAssignedQuery = /* GraphQL */ `
  query MoreTasksByAssigned(
    $itemId: ID!
    $assignedEmailPriority: ModelTaskPogoTaskByItemIdAndAssignedEmailCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskPogoTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ${tasksByItemIdAndAssigned}
  }
`;



// This query is for getting more counters.
export const itemCounterByItemIdQuery = /* GraphQL */ `
  query ItemCounterByItemId(
    $itemId: ID!
    $nextToken: String
  ) {
    itemCounterByItemId(
      itemId: $itemId
      nextToken: $nextToken
    ) {
      items {
        id
        name
        counter
      }
      nextToken
    }
  }
`;


const LoadingOverdueByDate = 0;
const LoadingOverdueByCounters = 1;
const LoadingSoonByDate = 2;
const LoadingSoonByCounters = 3;
const LoadingNullDates = 4;
const LoadingAllCounters = 5;

// This is a hook that fetches tasks that are overdue. It also fetches the item.
// I pass in setItem so that the item can be set in the parent component (and we
// don't re-call the query to get the tasks cuz the item changed).
export const useTaskQuery = (itemId, listType, sortType, setItem) => {
  const itemRef = useRef(null);
  const oldSort = useRef(null);

  // All items with a dueDate < now are overdue.
  const [now] = useState(new Date());
  const [soon] = useState(graphQLSoon());

  const loadingState = useRef(LoadingOverdueByDate);

  // This is the generic fetch function that fetches tasks by date, assigned, or priority.
  const fetchFunction = useCallback(async (query, variables, resultBucket, hasItemQuery) => {
    const itemsData = await client.graphql({ query: query, variables: variables });

    // Remember the item.
    if (hasItemQuery) {
      itemRef.current = itemsData.data.getTaskPogoItem;
      setItem(itemRef.current);
    }

    // First batch of overdue tasks based on date:
    var result = itemsData.data[resultBucket];
    var items = result.items;
    var nextToken = result.nextToken;
    addBucketNameToTasks(items);

    return [ items, nextToken, itemsData.data.getTaskPogoItem ];  
  }, [setItem]);  




  // This is the fetch for more tasks by date.
  const fetchByDate = useCallback(async (token, dueDate) => {
    var query = token ? moreTasksByDateQuery : tasksByDateQuery;;
    var resultBucket = "tasksByItemIdAndDueDate";
    var variables = { 
      itemId: itemId,
      sortDirection: 'ASC',
      filter: {dueDate: {ne: null, attributeExists: true}}
    };

    if (dueDate) {
      variables.dueDate = dueDate;
    } else {
      variables.filter = { 
        or: [ 
          {
            dueDate: {eq: null, attributeExists: true}
          },
          {
            dueDate: {attributeExists: false}
          }
        ]
      }
    }

    if (token) {
      variables.nextToken = token;
    }

    return fetchFunction(query, variables, resultBucket, itemRef.current == null);
  }, [fetchFunction, itemId]);


  // This is the fetch searching for overdue items by counter.
  const fetchUsingCounters = useCallback(async (token, includeFunction) => {
    let items = [];
    let nextToken = null;

    // TODO: Not true?????????
    // If there's a nextToken, we've been called to get more tasks. 
    if (token) {
      const itemsData = await client.graphql({ query: itemCounterByItemIdQuery, variables: { itemId: itemId, nextToken: token }});
      const newCounters = itemsData.data.itemCounterByItemId.items;

      // Add the new counters to the itemRef.current.itemCounter.items.
      for (let newCounter of newCounters) {
        itemRef.current.itemCounter.items.push(newCounter);
      }
    }

    // Start looking through the counters and find tasks that are overdue.
    counterLoop:
    for (let itemCounter of itemRef.current.itemCounter.items) {
      // If we've already processed this counter, skip it.
      if (itemCounter.processed) { continue }

      // Inspect the tasks for this counter looking for overdue tasks.
      let tasks = itemCounter.tasks.items;
      for (let task of tasks) {
        // If we've already processed this task, skip it.
        if (task.processed) { continue }

        if (includeFunction(itemCounter.counter, task.dueCounter)) {
          items.push(task);
          task.processed = true;
        } else {
          continue counterLoop;
        }
      }
      // All tasks processed, so mark this counter as processed.
      itemCounter.processed = true;


      // If there's a nextToken, return it, we'll get called back with it for more.
      // TODO: ??
      nextToken = itemCounter.tasks.nextToken;
      if (nextToken) { 
        // Don't want to return this nextToken again -- we'll use it in the next call and not again.
        itemCounter.tasks.nextToken = null;
        break;
      }
    }

    addBucketNameToTasks(items);
    return [items, nextToken];
  }, [itemId]);


  // Test if the counter is overdue.
  function counterIsOverdue(counter, taskCounter) {
    return counter >= taskCounter;
  }

  // Tests if a counter is soon.
  function counterIsSoon(counter, taskCounter) {
    return counter < taskCounter && counter <= taskCounter + soonHours;
  }

  // Tests if a counter is in the future.
  function counterIsFuture(counter, taskCounter) {
    return counter < taskCounter - soonHours;
  }

  // This calls the passed in fetch function and adds the items to the items array, if the
  // array doesn't already contain the item.
  async function doTheFetch(theFetch, arg1, arg2, items) {
    var [i, n] = await theFetch(arg1, arg2);

    // Add the items to the list, if they're not already there.
    for (let item of i) {
      // TODO: O(n)
      if (!items.find((element) => element.id === item.id)) {
        items.push(item);
      }
    }
    return [items, n];
  }

  // This is the fetch function when sorting by overdue. It's a state machine that fetches tasks by date and then by counter.
  const fetchSortOverdue = useCallback(async (token) => {
    console.log(`*** FetchItems ${token ? '' : 'No token'} ***`);
    var items = [];
    let nextToken = null;

    // If no token, we might be starting over. Start the state machine over.
    // If the sort type changed, go back to beginning of state machine.
    if ((null === token) || oldSort.current !== sortType) {
      loadingState.current = LoadingOverdueByDate;
      oldSort.current = sortType;
      itemRef.current = null;
    }

    // Loops until gets a nextToken or done.
    for (;;) {
      switch (loadingState.current) {
        case LoadingOverdueByDate:
          // In this state, I look for tasks that are overdue by date.
          console.log(`LoadingByDate: ${token ? 'with token' : 'null'}`);

          var [i, n] = await doTheFetch(fetchByDate, token, { lt: now.toISOString() }, items);
          items = i;
          nextToken = n;

          // No nextToken, move on to the next state.
          if (!nextToken) {
            loadingState.current = LoadingOverdueByCounters;
          }
          break;

        case LoadingOverdueByCounters:
          // In this state, I look through the item's counters and find tasks that are overdue.
          console.log(`LoadingOverdueByCounters: ${token ? 'with token' : 'null'}`);

          // TODO: What if I already added the task in LoadingFirst or LoadingByDate?
          [i, n] = await doTheFetch(fetchUsingCounters, token, counterIsOverdue, items);
          items = i;
          nextToken = n;

          // If there is no nextToken, move on to the next state.
          if (!nextToken) {
            loadingState.current = LoadingSoonByDate;
          }
          break;

        case LoadingSoonByDate:
          // Now load all the tasks that are soon.
          console.log(`LoadingSoonByDate: ${token ? 'with token' : 'null'}`);
          [i, n] = await doTheFetch(fetchByDate, token, soon, items);
          items = i;
          nextToken = n;

          // If no next token, move on to the next state.
          if (!nextToken) {
            loadingState.current = LoadingSoonByCounters;
          }
          break;

        case LoadingSoonByCounters:
          console.log(`LoadingSoonByCounters: ${token ? 'with token' : 'null'}`);

          // Now load all the tasks that are soon.
          [i, n] = await doTheFetch(fetchUsingCounters, token, counterIsSoon, items);
          items = i;
          nextToken = n;

          // If no next token, move on to the next state.
          if (!nextToken) {
            loadingState.current = LoadingNullDates;
          }
          break;

        case LoadingNullDates:
          console.log(`LoadingNullDates: ${token ? 'with token' : 'null'}`);

          // Now load all the dates that are null.
          // TODO: Use fetchByDate with a filter for null dates.
          [i, n] = await doTheFetch(fetchByDate, token, null, items);
          items = i;
          nextToken = n;

          // If no next token, we're done.
          if (null == nextToken) {
            loadingState.current = LoadingAllCounters;
          }
          break;

        case LoadingAllCounters:
          console.log(`LoadingAllCounters: ${token ? 'with token' : 'null'}`);
          [i, n] = await doTheFetch(fetchUsingCounters, token, counterIsFuture, items);
          items = i;
          nextToken = n;

          // If no next token, done.
          if (!nextToken) {
            return { items, nextToken };
          }
          break;
          
        default:
          console.error("Unknown state.");
          throw new Error("Unknown state.");
      }

      // I've used the passed in token. Clear it, so don't use it in next state if looping.
      token = null;

      // If there's a nextToken, return -- we'll get called back for when the user scrolls to the bottom of the list.
      // If there isn't a nextToken, we should have moved to the next state in the state machine.
      if (nextToken) {
        return { items, nextToken };
      }
    }
  }, [fetchByDate, fetchUsingCounters, now, soon, sortType]);


  const fetchSortAssigned = useCallback(async (token) => {
    let query = `query TasksByItemIdAndAssigned(
      $itemId: ID!
      $assignedEmailPriority: ModelTaskPogoTaskByItemIdAndAssignedEmailCompositeKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter1: ModelTaskPogoTaskFilterInput
      $filter2: ModelTaskPogoTaskFilterInput
      $limit: Int
      $nextToken: String
    ) {
      ${token ? '' : getTaskPogoItem}

      first: tasksByItemIdAndAssigned(
        itemId: $itemId
        assignedEmailPriority: $assignedEmailPriority
        sortDirection: $sortDirection
        filter: $filter1
        limit: $limit
        nextToken: $nextToken
    ) {
        items {
          ${taskFields}
        }
        nextToken
      }

      second: tasksByItemIdAndAssigned(
        itemId: $itemId
        assignedEmailPriority: $assignedEmailPriority
        sortDirection: $sortDirection
        filter: $filter2
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ${taskFields}
        }
        nextToken
      }
    }
    `;

    var variables = { 
      itemId: itemId,
      sortDirection: 'ASC',
      filter1: { 
        and: [ 
          {
            assignedEmail: {ne: null, attributeExists: true}
          },
          {
            assignedEmail: {ne: '', attributeExists: true}
          }
        ]
      },
      filter2: {
        or: [ 
          {
            assignedEmail: {eq: null, attributeExists: true}
          },
          {
            assignedEmail: {eq: '', attributeExists: true}
          }
        ]
      }
    };
    
    const itemsData = await client.graphql({ query: query, variables: variables });

    // Remember the item.
    if (!token) {
      itemRef.current = itemsData.data.getTaskPogoItem;
      setItem(itemRef.current);
    }

    // First batch of overdue tasks based on date:
    var result = itemsData.data.first;
    var items = result.items;
    addBucketNameToTasks(items);

    // TODO: How to handle tokens?
    var nextToken = result.nextToken;
    if (!nextToken) {
      result = itemsData.data.second;
      items = items.concat(result.items);
      nextToken = result.nextToken;
    }

    return { items, nextToken };
  }, [fetchFunction, itemId, sortType]);



  const fetchSortPriority = useCallback(async (token) => {
    let query = `query TasksByItemIdAndPriority(
      $itemId: ID!
      $priorityAssignedEmail: ModelTaskPogoTaskByItemIdAndPriorityCompositeKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter1: ModelTaskPogoTaskFilterInput
      $filter2: ModelTaskPogoTaskFilterInput
      $limit: Int
      $nextToken: String
    ) {
      ${token ? '' : getTaskPogoItem}

      first: tasksByItemIdAndPriority(
        itemId: $itemId
        priorityAssignedEmail: $priorityAssignedEmail
        sortDirection: $sortDirection
        filter: $filter1
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ${taskFields}
        }
        nextToken
      }

      second: tasksByItemIdAndPriority(
        itemId: $itemId
        priorityAssignedEmail: $priorityAssignedEmail
        sortDirection: $sortDirection
        filter: $filter2
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ${taskFields}
        }
        nextToken
      }
    }
    `;

    var variables = { 
      itemId: itemId,
      sortDirection: 'ASC',
      filter1: { 
        and: [ 
          {
            priority: {ne: 0, attributeExists: true}
          }
        ]
      },
      filter2: {
        or: [ 
          {
            priority: {eq: 0, attributeExists: true}
          },
          {
            priority: {attributeExists: false}
          }
        ]
      }
    };
    
    const itemsData = await client.graphql({ query: query, variables: variables });

    // Remember the item.
    if (!token) {
      itemRef.current = itemsData.data.getTaskPogoItem;
      setItem(itemRef.current);
    }

    // First batch of overdue tasks based on date:
    var result = itemsData.data.first;
    var items = result.items;
    addBucketNameToTasks(items);

    // TODO: How to handle tokens?
    var nextToken = result.nextToken;
    if (!nextToken) {
      result = itemsData.data.second;
      items = items.concat(result.items);
      nextToken = result.nextToken;
    }

    return { items, nextToken };
  }, [fetchFunction, itemId, sortType]);



  const fetchItems = useCallback(async (token) => {
    switch (sortType) {
      case SortOverdue:
        return fetchSortOverdue(token);
      case SortAssigned:
        return fetchSortAssigned(token);
      case SortPriority:
        return fetchSortPriority(token);
      default:
        throw new Error(`Unknown sort type ${sortType}`);
    }
  }, [fetchSortAssigned, fetchSortOverdue, sortType]);

  return  fetchItems;
}


