// Home page

import { PrimaryText, ItemsHelp, Ruler, Overdue, DueSoon } from '../components';
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";
import { urlForAllItems } from '../fleet-shared/Urls';

const Info = ({show, children}) => {

  if (show) {
    return (
      <PrimaryText>
        {children}
      </PrimaryText>
    );
  }

  return null;
}



const Home = ( { user } ) => {
  const navigate = useNavigate();

  function gotoItems() {
    navigate(urlForAllItems());
  }

  return (
    <>
    <Info show={user !== null}>
      <div className='p-2'>Welcome back, {user && user.username}.</div>
      <Button variant="primary" className="mb-2" onClick={gotoItems}>
        Go to your Items
      </Button>
      <Ruler/>
    </Info>

    <PrimaryText>
      <div>
        TaskPogo lets you track <b>Items</b> with <b>Tasks</b>.
        You can see when <b>Tasks</b> are <Overdue/> or <DueSoon/>.
      </div>
      <Ruler/>
    </PrimaryText>

    <ItemsHelp/>

    <PrimaryText>
      <div>
        You can set up email subscriptions to be notified when <b>Tasks</b> are <Overdue/> or <DueSoon/>.
      </div>
    </PrimaryText>

    <Info show={user === null}>
      <Button variant="primary" className="mb-2" onClick={gotoItems}>
        Create Account or Log In
      </Button>      
    </Info>

    </>
  );
}

export default Home;

