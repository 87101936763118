// Makes a UI element to show a status. Status's defined in TaskBuckets.TaskStatusToBage.

import * as TaskBuckets from '../fleet-shared/TaskBuckets.mjs';

const StatusBadge = ({status}) => {
    var style = 'badge-primary';
    var name = '?';

    const cleanStatus = status ? status : '';

    let statusToBadge = TaskBuckets.taskStatusToBadge(cleanStatus);
    if (statusToBadge) {
        style = statusToBadge.style;
        name = statusToBadge.name;
    }

    return (
        <span className={`badge badge-pill ${style}`}>
            {name}
        </span>
    );
}

export default StatusBadge;