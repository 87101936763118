// Shows a card with a heading, subheading, count of items in an array of Tasks, a variable number of tasks and then a link to show all if there are more
// to show. You can set an icon to show and color to use.
//
// prop.heading is the heading for the Task Summary.
// prop.subheading is the sub-heading for the Task Summary.
// props.icon is the FontAwesome glyph to show (e.g. "fa-exclamation-circle")
// props.color is the color to use (e.g. "text-danger")
// props.bucket is the bucket showing summary (see utilties/TaskBuckets for fields -- like name, icon, color, numTasksToShow, [tasks], taskUrl).
// props.onClickTask is a function called when click on a task. Takes the task as a parameter.
// props.numTasksToShow is # of tasks to show (and then a "see all")
// props.setShowTaskBucket is a function that takes a bool to show a bucket of tasks, from setTaskBucket
// props.setTaskBucket is a function that sets the task bucket setShowTasksList shows.

function TaskSummary(props) {
  // Ask the parent to show all our tasks.
  function onClickSeeAll() {
    props.setTaskBucket(props.bucket);
    props.setShowTaskBucket(true);
  }

  const singleTask = (task) => {
    return (
      <tr key={task.id} onClick={() => props.onClickTask(task)}>
        <td>
          <div className="entered-data">
            {task.name}
          </div>
        </td>
      </tr> 
    );
  }

  const taskLine = (task, index) => {
    if (index < props.numTasksToShow) {
      return singleTask(task);
    } else if (index === props.numTasksToShow) {
      return (
        <tr key='seeAllButtonId' onClick={() => onClickSeeAll()}>
          <td>"See all..."</td>
        </tr> 
      );
    }

    return null;
  }

  const taskList = () => {
    if (props.bucket.tasks === null) {
      return <tr key='noneTextId'><td>"None"</td></tr>;
    }

    return props.bucket.tasks.map( (task, index) => { 
      return taskLine(task, index);
    });
  }

  return (
    <div className="col-xl-3 col-sm-6 col-12 mb-0">
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between px-md-1">
            <div className="align-self-center">
              <i className={"fas fa-3x " + props.icon + " " + props.color}></i>
            </div>
            <div className="text-end" onClick={onClickSeeAll}>
              <h5>{props.heading}</h5>
              <p>{ (props.bucket.tasks !== null) ? (props.bucket.tasks.length + " task" + (props.bucket.tasks.length > 1 ? "s" : "")) : "Loading..." }</p>
            </div>
          </div>
        </div>

        <div>
          <table className="table table-hover text-nowrap table-striped">
            <tbody>
              { taskList() }
            </tbody>
          </table>
        </div>

      </div>
    </div>
  )
}

export default TaskSummary;
