import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export const PickLogbookAddType = ({ show, onHide, onSelectTask, onAddAdHoc }) => (
    <Modal show={show}>
        <h3><div className='text-center'>Add to Logbook</div></h3>
        <div className='text-center p-1'>
            <Button className='btn btn-primary col-6' onClick={onSelectTask}>
                Select from Tasks List
            </Button>
        </div>
        <div className='text-center p-1'>
            <Button className='btn btn-primary col-6' onClick={onAddAdHoc}>
                Add/Edit a new Entry
            </Button>
        </div>
        <div className='text-center p-3'>
            <Button className='btn btn-danger col-6' onClick={onHide}>
                Cancel
            </Button>
        </div>
    </Modal>
)