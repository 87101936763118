
import { itemFields } from './ItemFields.mjs';
import { databaseDateTimeString } from './Common.mjs';
import * as TaskBuckets from './TaskBuckets.mjs';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc.js';
import customParseFormat from 'dayjs/plugin/customParseFormat.js';
dayjs.extend(utc);
dayjs.extend(customParseFormat);

// Constants used in email subcriptions

// Used as a MessageBody in my SQS queues to identify type of email to be sent.
export const emailTypeItemStatus = 'itemStatus';

// Used for sendEveryDays.
export const emailSendDaily = 1;
export const emailSendWeekly = 7;
export const emailSendMonthly = 30;
export const emailSendQuarterly = 90;
export const emailSendEverySixMonths = 180;
export const emailSendYearly = 365;


// Used with copyOnlyKnownFields for database saving.
export const emailFields = [
  {name: "ID", key: "id", type: "text"},
  {name: "emailType", key: "emailType", type: "text"},
  {name: "email", key: "email", type: "text"},
  {name: "name", key: "name", type: "text"},
  {name: "sendHourGMT", key: "sendHourGMT", type: "number"},
  {name: "itemId", key: "itemId", type: "text"},
  {name: "sendEveryDays", key: "sendEveryDays", type: "number"},
  {name: "lastSentDate", key: "lastSentDate", type: "text"},
  {name: "nextSendDate", key: "nextSendDate", type: "text"},
  {name: "unsubscribeKey", key: "unsubscribeKey", type: "text"}
];

// Given a subscription, calculate the next Send date assuming just sent. Returns in databaseDate format.
export function calculateNextSendDate(subscription) {
    let sendEveryDays = subscription.sendEveryDays;
    let sendHourGMT = subscription.sendHourGMT;
    let now = dayjs();
    let nextSendDate;

    switch (sendEveryDays) {
    case emailSendDaily:
    case emailSendWeekly:
    default:
        // These guys don't need any special handling, just add the # of days:
        nextSendDate = now.add(sendEveryDays, 'day');
        break;
    case emailSendMonthly:
        // Monthly varies # of days based on a 28, 29, 30, or 31 day month... Dayjs handles.
        // Note if say on 1/31, and bump a month it'll set the day to 2/28.
        nextSendDate = now.add(1, 'month');
        break;
    case emailSendQuarterly:
        // Add 3 months... Same day of month (or earlier if new month is shorter and end of this month).
        nextSendDate = now.add(3, 'month');
        break;
    case emailSendEverySixMonths:
        // Add 6 months... Same day of month (or earlier if new month is shorter and end of this month)..
        nextSendDate = now.add(6, 'month');
        break;
    case emailSendYearly:
        // Add a year to today.
        nextSendDate = now.add(1, 'year');
        break;
    }

    let nextSendDateTime = nextSendDate.hour(sendHourGMT).minute(0).second(0).millisecond(0);

    return databaseDateTimeString(nextSendDateTime);
}

/***** Helper functions for taking info out of an Item for use by the backend Email functions *******/

export function emailItemInfo(item) {
    return itemFields.filter( (field) => {
        return (item[field.key] && item[field.key].length > 0);
    }).map( (field) => {
        return {name: field.name, value: item[field.key]};
    });
}

export function emailCounters(item) {
    return item.itemCounter.items.map( (counter) => {
        return { name: counter.name, value: counter.counter};
    });
}

export function emailTasks(item) {
    // Tasks buckets -- overdue, soon, all.
    let buckets = TaskBuckets.taskBuckets(item);

    return buckets.map( (bucket) => {
        return { name: bucket.name, value: bucket.tasks.length }
    });
}

export function emailLogbook(item)  {
    return item.completedTasks.items.map( (task) => {
        return { name: task.date, value: task.name };
    });
}