import { useEffect, useState, useCallback } from 'react';
import { Spinner } from '../../components';
import { nameSoon, nameOverdue } from '../../fleet-shared/TaskBuckets';
import { client } from '../../utilities/Database.mjs';
import { rangesForTasks } from '../../fleet-shared/TaskBuckets.mjs';
import { useStatusMessage } from '../../components';
import { useIsMounted } from '../../utilities/useMounted';


export const TaskSummary = ({ itemId }) => {
  const [buckets, setBuckets] = useState(null);
  const [StatusMessage, {showError, hideStatus}] = useStatusMessage();
  const isMounted = useIsMounted();

  // Lists all tasks for an item. Returns [tasks, counters]
  const listTasksAndCountersForItemId = useCallback(async (itemId) =>  {
    // GraphQL to get all the tasks due counters/dates, for an item.
    const graphQL_TaskDueInformationByItemId = `
      query TasksByItemId(
        $itemId: ID!,
        $nextToken: String
      ) {
        tasksByItemIdAndName(
          itemId: $itemId,
          nextToken: $nextToken
        ) {
          items {
            dueCounter
            dueDate
            itemCounter {
              counter
            }
          }
          nextToken
        }
      }
    `;

    console.log(`Fetching tasks/counters for ${itemId}`);

    hideStatus();
    
    let nextToken = null;
    let allTasks = [];

    // Get all the tasks for the item. Keep calling until we have them all (when nextToken is null).
    // TODO: Limit to so many tasks, so don't do lots of database roundtrips? Would need to show > xx tasks in the UI.
    do {
      let fetch = await client.graphql({query: graphQL_TaskDueInformationByItemId, variables: { itemId: itemId, nextToken: nextToken}});

      let tasks = fetch.data.tasksByItemIdAndName.items;
      allTasks = allTasks.concat(tasks);
      nextToken = fetch.data.tasksByItemIdAndName.nextToken;

      if (nextToken) {
        console.log(`Fetching more tasks for ${itemId}`);
      }
    } while (nextToken);

    // addBucketNameToTasks(allTasks);
    return allTasks;
  }
  , [hideStatus]);

  useEffect(() => {
    // Fetch tasks/counters for count up overdue/soon tasks.
    async function fetchTasks() {
      try {
        let tasks = await listTasksAndCountersForItemId(itemId);

        if (!isMounted()) { 
          console.error(`${itemId} is not mounted?`);
          return; 
        }

        let ranges = rangesForTasks(tasks);
        let buckets = { total: 0, soon: 0, overdue: 0 };

        ranges.forEach((range) => {
          if (range.name === nameSoon) {
            buckets.soon = range.numTasks;
          } else if (range.name === nameOverdue) {
            buckets.overdue = range.numTasks;
          } else {
            buckets.total = range.numTasks;
          }
        });
        
        setBuckets(buckets);
      } catch (err) {
        if (!isMounted()) { 
          return; 
        }
        showError(err);
      }
    }

    fetchTasks();
  }, [isMounted, itemId, listTasksAndCountersForItemId, showError]);

  if (buckets) {
    return (
      <span>
        <StatusMessage />
        {buckets.total} tasks, {buckets.overdue} <span className='text-danger'><b>overdue</b></span>, {buckets.soon} <span className='text-warning'><b>due soon</b></span>.
      </span>
    );
  } else {
    return (
      <span>
        <StatusMessage />
        <Spinner /><span className='p-2 text-secondary'>Loading tasks...</span>
      </span>
    );
  }
};
