// Shows a card with upcoming tasks in various buckets (overdue, soon, all, etc.). Shows only
// a few from each bucket. When click on a task, calls parent to edit it. If too many tasks to
// show, shows an show-all button and calls parent to show all the tasks if click the button.
//
// props.buckets          - See utilties/TaskBuckets. The various buckts of tasks to display.
// props.onClickTask      - A function to call when a task is clicked on. Takes the task as a parameter.
// props.setTaskBucket    - A function to call to set a bucket of tasks to show (used with props.setShowTaskBucket).
// props.setShowTasksBucket - A function to call to show the task list UI. Used with props.setTaskBucket.
// props.addTask          - A function to call to add a task and present UI to edit it.

import React from 'react';
import TaskSummary from './TaskSummary';
import DataCard from './DataCard';
import { nameAll } from '../fleet-shared/TaskBuckets.mjs';
import { CardHeader } from './CardHeader';
import { useStatusMessage } from './StatusMessage';

const TaskSummaries = ({ buckets, onClickTask, setShowTaskBucket, setTaskBucket }) => {
  return buckets && buckets && buckets.map( (bucket, index) => { 
    return <TaskSummary 
      key={index}
      className="col-4" 
      heading={bucket.name}
      bucket={bucket} 
      icon={bucket.icon}
      color={bucket.color}
      onClickTask={onClickTask}
      numTasksToShow={bucket.numTasksToShow}
      setShowTaskBucket={setShowTaskBucket}
      setTaskBucket={setTaskBucket}
    />
  });

}


const UpcomingTasks = (props) => {
    // Loading/error UI
    const [StatusMessage, {showText, showError, hideStatus}] = useStatusMessage();

    function seeAll() {
      let allBucket = props.buckets.find( (bucket) => bucket.name === nameAll);
      props.setTaskBucket(allBucket);
      props.setShowTaskBucket(true);
    }

    async function handleAdd() {
      try {
          showText('Adding');
          await props.addTask();
          hideStatus();
      } catch (err) {
          showError(err);
      }
    }
  
  
    return (
      <div>
        <StatusMessage />
        <DataCard
          header=<CardHeader text="Upcoming Tasks" addTapped={handleAdd} seeAllTapped={seeAll} />
          body={
            <div className="row justify-content-center">
              <TaskSummaries buckets={props.buckets} onClickTask={props.onClickTask} setShowTaskBucket={props.setShowTaskBucket} setTaskBucket={props.setTaskBucket} />
            </div>
          }
        />
      </div>
    )
}

export default UpcomingTasks;
