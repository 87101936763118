// Modal component to edit an ItemCounter entry.
//
// props.counter is the ItemCounter entry to edit.
// props.save is an async function that is called when save is tapped.
// props.showDelete is a boolean to show the delete button or not.
// props.delete is a function that is called when delete is tapped.
// props.show is used to show/hide the UI.
// props.onHide is a function used to hide the UI.

import { useEffect, useRef } from 'react';
import ModalEditForm from './ModalEditForm';
import * as Common from '../fleet-shared/Common.mjs';


const counterFields = [
  {name: "Name",   key: "name",  type: "text"},
  {name: "Counter",  key: "counter", type: "number"},
];

const EditHours = (props) => {
  // Watch for when unmounted, so don't do things if this object is discarded
  // during an async operation. This happens when the edit UI is up and dismissed
  // due to the object changing and the whole UI gets re-rendered.
  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  }, [])


  async function handleSave(counter) {
    // Make sure have required fields.
    counter.name = Common.trimmedStringOrNull(counter.name);
    if (null == counter.name) {
      throw new Error("Name required");
    }

    // Make API call to save.
    await props.save(counter);
  }

  async function handleDelete() {
    await props.delete(props.counter.id);
    props.onHide();
  }


  return (
    (props.counter != null) && 
      <ModalEditForm
        show={props.show}
        onHide={props.onHide}
        fields={counterFields}
        item={props.counter}
        save={handleSave}
        delete={handleDelete}
      />
  );
}

export default EditHours;
