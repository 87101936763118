// props.equipment is [Item]
// props.heading is a message to put in a heading at top.
// props.addEquipment is a function that adds a new piece equipment to the parent.

import { useNavigate } from "react-router-dom";
import { InfoCard, useStatusMessage } from '.';
import * as Urls from '../fleet-shared/Urls.mjs';
import { CardHeader } from './CardHeader';

function EquipmentList(props) {
  const navigate = useNavigate();

  // Loading/error UI
  const [StatusMessage, {showText, showError, hideStatus}] = useStatusMessage();

  async function handleAdd() {
    try {
        showText('Adding');
        await props.addEquipment();
        hideStatus();
    } catch (err) {
        showError(err);
    }
  }

  return (
    <div>
      <StatusMessage />
      <InfoCard 
        header=<CardHeader text={props.heading} addTapped={handleAdd} />
        columnInfo={[
          {name: 'Name', key: 'name'},
        ]}
        data={props.equipment}
        onClickRow={(item) => { navigate(Urls.urlForItem(item.id)) }}
        noTitle="true"
      />
    </div>
  )
}

export default EquipmentList

