import React from 'react';
import { Link } from "react-router-dom";
import ErrorText from './ErrorText';

function PageNotFound() {
    return (
        <div className='text-center'>
            <ErrorText text={'The requested page does not exist.'}/>
            <div>
                 <Link to='/'>Go home.</Link>
            </div>
        </div>
    )
}

export default PageNotFound