// Loads a list of top level items from the database and shows them.
// Shows the full size image the user uploaded for an item's avatar/thumbnails.

import { useNavigate, useParams } from 'react-router-dom';
import { urlForItem } from '../../fleet-shared/Urls';
import { Thumbnail, ThumbnailLarge } from './Thumbnail';
import { Button } from 'react-bootstrap';

export const ItemImage = ({ user }) => {
  // Passed on URL:
  const { itemId } = useParams();

  // For navigating back to Item page.
  const navigate = useNavigate();

  function goBack() {
    navigate(urlForItem(itemId));
  }

  return (
    <div className="text-center pt-2">
      <Thumbnail itemId={itemId} identityId={user && user.identityId} size={ThumbnailLarge} />
      <Button variant='info' className='mx-2 m-2' size='sm' onClick={goBack}>
        Back to Item
      </Button>

    </div>
  )
}

export default ItemImage;
