// Support page

const Support = () => {
  return (
    <div className="text-center">
      <div>
        Contact the team at <b>info@taskpogo.com</b>
      </div>
    </div>
  )
}  

export default Support;

