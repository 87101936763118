// Pick from the item's tasks an entry to be added to the logbook.

import { useParams, useNavigate } from "react-router-dom";
import { useStatusMessage } from '../../components';
import { copyTaskToCompletedTasks } from '../../utilities/Database.mjs';
import { TaskTable } from '../task/TaskTable';
import { useTaskQuery } from '../task/TaskQuery';
import { bucketNameAll } from '../../fleet-shared/Urls.mjs';
import { SortOverdue } from '../task/Tasks.jsx';
import { urlForLogbookEntryFromTask } from '../../fleet-shared/Urls.mjs';
import { ItemBreadcrumbs } from '../item/ItemBreadcrumbs';
import { useState } from "react";

// TODO: This should be infinite scroll, like the other task pages. And pass in a fetch function to TaskTable.
export const LogbookPickTask = () => {
  const { itemId } = useParams();
  const navigate = useNavigate();
  const [item, setItem] = useState(null);

  const fetchItems = useTaskQuery(itemId, bucketNameAll, SortOverdue, setItem);

  const [StatusMessage, {showText}] = useStatusMessage();

  async function taskClicked(task) {
    showText('Adding');

    // Add entry to database.
    let newTask = await copyTaskToCompletedTasks(task, item);

    // Navigate to the page to edit the entry.
    // TODO: Edit entry page, not from task page... With flag to show update task...
    
    navigate(urlForLogbookEntryFromTask(itemId, newTask.id));
  }


  return (
    <div className="container pt-2">
      <div className="card">
        <div className="card-header text-center">
          <ItemBreadcrumbs item={item} />

          <StatusMessage/>

          <h3>Choose a Task to add to the Logbook</h3>

          <TaskTable fetchItems={fetchItems} onTaskClicked={taskClicked} />
        </div>
      </div>
    </div>
  );
}

export default LogbookPickTask;
