// Modal component to edit a task.
//
// Usage:
//  const saveEditedItem = useCallback(async (newItem) => {
//    ...
//  }, [item]);
//   import ModalEditForm from './ModalEditForm';
//   import { taskFields } from '../utilities/TaskFields.js';
//   const [showEditTask, setShowEditTask] = React.useState(false);
//   <ModalEditForm
//     show={showEditTask}
//     onHide={() => setShowEditTask(false)}
//     fields={taskFields}    <-- or ItemFields = [{
//                                     name: "Item Title", 
//                                     key: "{Key into editItem[]}" 
//                                     type="[text|date|number|select]"
//                                     selects=[key:[<selectItems>]]
//                                    }]
//     item={editItem}
//     save={saveEditedItem}
//     delete={deleteItem}  // If you want a delete button, not specified for none.
//     title= optional title
//   />
// "selects" is only required if there is a taskFields of type "select".
// It is a map of key for the field mapping to an array of items for the
// select.
//


import Modal from 'react-bootstrap/Modal';
import { EditForm } from './EditForm';

function ModalEditForm(props) {
  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title || 'Edit Item'}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <EditForm
          item={props.item}
          fields={props.fields}
          saveItem={props.save}
          deleteItem={props.delete}
          canceled={props.onHide}
        />
      </Modal.Body>
    </Modal>
  );
}

export default ModalEditForm;
