// Logbook for an item

import { useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
    DataCard, 
    useStatusMessage, 
    EditLogbookEntry, 
    LogbookHelp, 
    PickLogbookAddType, 
    Confirm } from '../../components';
import * as Database from '../../utilities/Database.mjs';
import { urlForLogbookPickTask, urlForLogbookEntry } from '../../fleet-shared/Urls.mjs';
import { displayDate } from '../../fleet-shared/Common.mjs';
import { useMyInfiniteScroll } from '../../components/MyInfiniteScroll';
import { CardHeader } from '../../components/CardHeader';
import { ItemBreadcrumbs } from '../item/ItemBreadcrumbs';

import { generateClient } from 'aws-amplify/api';
const client = generateClient();

// Logbook entry row
const LogbookEntry = ({entry, onDetails}) => {
  return (
    <div className='logbook-row entered-data' onClick={ () => onDetails(entry) } >
      <div className='logbook-row-item'>{displayDate(entry.date)}</div>
      <div className='logbook-row-item'>{entry.counter}</div>
      <div className='logbook-row-item'>{entry.name}</div>
      <div className='logbook-row-item'>
        <button className='m-1 btn btn-info' onClick={() => onDetails(entry)}>
            More
        </button>
      </div>
    </div>
  );
}

/*
    getTaskPogoItem(id: $itemId) {
      id
      name
    }

*/

const completedTasksByItemId = /* GraphQL */ `
  completedTasksByItemId(
    itemId: $itemId
    date: $date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      itemId
      date
      itemCounterId
      taskId
      description
      taskNotes
      dueCounter
      dueDate
      counterInterval
      daysInterval
      notes
      counter
      owner
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
`;

const initialCompletedTasksByItemIdQuery = /* GraphQL */ `
  query CompletedTasksByItemId(
    $itemId: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskPogoCompletedTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTaskPogoItem(id: $itemId) {
      id
      name
    }
    ${completedTasksByItemId}
  }
`;

const moreCompletedTasksByItemIdQuery = /* GraphQL */ `
  query CompletedTasksByItemId(
    $itemId: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskPogoCompletedTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ${completedTasksByItemId}
  }
`;


const Logbook = () => {
  const { itemId } = useParams();

  const [showConfirm, setShowConfirm] = useState(false);
  const [entryToDelete, setEntryToDelete] = useState(null);
  const [item, setItem] = useState(null);

  const [StatusMessage, {showText, showError, hideStatus}] = useStatusMessage();

  const renderChildren= (entries) => {
    return (
      <div className='logbook-wrapper'>
        { entries.map((entry, index) => (
          <LogbookEntry key={index} entry={entry} onDetails={onDetails} />
        ))}
      </div>
    );
  }

  // Go get the next page of entries.
  const fetchMoreEntries = useCallback(async (pageToken) => {
    // Fetch logbook entries for the current page. Note that nextToken could be null, so use expansion
    // operator to optionally add it. On the initial query, there's no nextToken and I get some item date
    // for the Breadcrumbs.
    const query = { 
      query: pageToken ? moreCompletedTasksByItemIdQuery : initialCompletedTasksByItemIdQuery, 
      variables: { 
        itemId: itemId, 
        sortDirection: 'DESC', 
        ...(pageToken && { nextToken: pageToken} )
        // TODO: Just for testing, remove:
        // limit: 2 
      }
    };
    let results = await client.graphql(query);

    if (results.data.getTaskPogoItem) {
      let item = results.data.getTaskPogoItem;
      setItem(item);
    }

    return  { 
      nextToken: results.data.completedTasksByItemId.nextToken, 
      items: results.data.completedTasksByItemId.items 
    };
}, [itemId]);

const endMessage = () => {
  return (
    <div className='logbook-end'>
      <b>&#8212;End of logbook&#8212;</b>
    </div>
  );
}

const { Control, entries, setEntries } = useMyInfiniteScroll(
  renderChildren, 
  fetchMoreEntries,
  endMessage
  );

const [showEditLogbookEntry, setShowEditLogbookEntry] = useState(false);
const [editLogbookEntry, setEditLogbookEntry] = useState(null);
const [showPickLogbook, setShowPickLogbook] = useState(false);


const navigate = useNavigate();

// Called when a new task is added to the DB. Shows in UI and starts editing it.
function onAdded(newTask) {
  let newEntries = [newTask, ...entries];
  setEntries(newEntries);
  setEditLogbookEntry(newTask);
  setShowEditLogbookEntry(true);
  setShowPickLogbook(false);
}

async function handleAdd() {
  await Database.createCompletedTaskWithUI(itemId, showText, showError, hideStatus, onAdded);
}

// Passed to EditTask.  When the Save button is pressed, this is called.
// throws on errors.
const saveEditedTask = useCallback(async (task) => {
  // Copy ID's into the saved task.
  task.id = editLogbookEntry.id;
  task.itemId = itemId;

  // Update the database, can throw.
  await Database.updateCompletedTask(task);

  // And UI.
  var newEntries = [...entries];
  newEntries[0] = task;
  setEntries(newEntries);
  setShowEditLogbookEntry(false);
}, [editLogbookEntry, itemId, entries, setEntries]);


// User clicked delete, confirm.
function onClickDelete(entry) {
  setEntryToDelete(entry);
  setShowConfirm(true);
}


// User confirmed delete, go delete.
async function onConfirmDelete() {
  showText('Deleting');

  await Database.deleteCompletedTask(entryToDelete.id);

  // Update model
  let tasks = entries.filter( (task) => { 
    return task.id !== entryToDelete.id;
  });
  hideStatus();

  setEntries(tasks);
  setShowConfirm(false);
}


function handleSelectTaskForLogbook() {
  // Navigate to logbook/picktask
  navigate(urlForLogbookPickTask(itemId));
}



  // Click on row, go to details.
  function onDetails(entry) {
    navigate(urlForLogbookEntry(itemId, entry.id));
  }

  // TODO: The logbook-date, logbook-counter, logbook-name, logbook-more etc. Should then be xxx-column for css?
  
  // Show the entries in a table
  return (
    <div className='pb-2]'>
      <ItemBreadcrumbs item={item} />
     
      <StatusMessage />

      { (entries && entries.length === 0) ? <LogbookHelp /> : null }

      <DataCard
        header=<CardHeader text='Logbook' addTapped={ () => setShowPickLogbook(true) } />
        body={entries ? 
              <div className='logbook pb-2'>
                <div className='logbook-header'>
                  <div className='logbook-row-item'>Date</div>
                  <div className='logbook-row-item'>Counter</div>
                  <div className='logbook-row-item'>Name</div>
                  <div className='logbook-row-item'>Details</div>
                </div>

                <Control />

                <EditLogbookEntry
                  show={showEditLogbookEntry}
                  onHide={() => setShowEditLogbookEntry(false)}
                  task={editLogbookEntry}
                  item={item}
                  save={saveEditedTask}
                  deleteEntry={() => onClickDelete(editLogbookEntry)}
                />
          
                <button className='mx-1 btn btn-success' onClick={() => setShowPickLogbook(true) }>
                  Add
                </button>
          
                <PickLogbookAddType 
                  show={showPickLogbook}
                  onHide={() => setShowPickLogbook(false)}
                  onSelectTask={() => handleSelectTaskForLogbook()}
                  onAddAdHoc={handleAdd}
                />
          
                <Confirm
                  show={showConfirm}
                  onConfirm={onConfirmDelete}
                  onHide = {() => setShowConfirm(false)}
                  body='Are you sure?'
                  confirmText='Confirm Delete'
                  title={`Delete ${entryToDelete && entryToDelete.name}`}>
                </Confirm>
              </div>
          : null
        }
      />
    </div>
  );
}

export default Logbook;

