import { useState, useEffect } from 'react';
import { storageGet } from '../../utilities/Database';

// This is React Hook that goes to storage to get a link to a file. 
// The hook returns a URL to the file. When initialized, we don't
// have a file, and URL is null. This goes to Storage to see if the 
// file exists, if so, the URL is set. If there's an error, 
// onStorageGetError is called.
export const useStorageURL = ({
  key, onStorageGetError
}) => {
  const [url, setURL] = useState(null);

  useEffect(() => {
    async function load() {
      try {
        console.log(`Fetching image key: ${key}`);

        // Get the URL, throw if doesn't exist.
        let file = await storageGet(key, true);

        if (ignore) {
          return;
        }

        setURL(file.url.toString());
      } catch (err) {
        if (ignore) {
          return;
        }

        if (onStorageGetError) {
          onStorageGetError(err);
        }
      }
    }

    // We are async, if we are destroyed before return, ignore is set.
    let ignore = false;

    // Do the async load (if we have a key).
    if (key) {
      load();
    }

    return () => {
      ignore = true;
    };
  }, [key, onStorageGetError]);

  return [url, setURL];
};
