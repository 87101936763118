// A component that renders a single task in a line in a TaskTable 

import { StatusBadge } from '../../components';
import { displayDate } from '../../fleet-shared/Common.mjs';
import { useNavigate } from 'react-router-dom';
import { urlForTaskId } from '../../fleet-shared/Urls.mjs';
// import Task from './Task';


const TaskLineColumn = ({ items, className, onTaskClicked }) => {
  return (
      <div className='tasks-row-item'>
        {items.map((item, index) => {
          return (
            <div key={index}>{item}</div>
          );          
        })}
      </div>
  );
}

export const TaskLine = ({ task, onTaskClicked, children}) => {
  const navigate = useNavigate();

  function formatCounterInterval(counterInterval) {
    if (counterInterval > 0) {
      return `then every ${counterInterval} ${task.itemCounter ? task.itemCounter.name : ''}`;
    } else {
      return '';
    }
  }

  function formatDaysInterval(daysInterval) {
    if (daysInterval > 0) {
      return `then every ${daysInterval} days`;
    } else {
      return '';
    }
  }


  // A task was clicked, call the callback function if it exists. Or go to the task
  function taskClicked(task) {
    if (onTaskClicked) {
      onTaskClicked(task);
      return;
    }

    let url = urlForTaskId(task.itemId, task.id);
    navigate(url);
  }
  
  return (
    <div className='tasks-row entered-data' onClick={() => taskClicked(task) }>
      <TaskLineColumn items={[<StatusBadge status={task.bucketName}/>]} />
      <TaskLineColumn items={[task.name, task.description, task.notes]} />
      <TaskLineColumn items={[task.dueCounter, task.itemCounter ? task.itemCounter.name : '', formatCounterInterval(task.counterInterval)]} />
      <TaskLineColumn items={[displayDate(task.dueDate), formatDaysInterval(task.daysInterval)]} />
      <TaskLineColumn items={[task.assignedEmail, task.priority]} />
      {children}
    </div>
  );
}