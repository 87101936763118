// props.itemId is the Item's ID this array belongs to.
// props.counterItems is an [ItemCounter] to show
// props.save is an async function to save edited counter.
// props.delete is a function to delete counter, given an ID.

import React, { useEffect, useRef } from 'react'
import { DataCard, useStatusMessage, HourSummary, EditHours, PrimaryText } from '.';
import * as Database from '../utilities/Database.mjs';
import { CardHeader } from './CardHeader';

// Either show there are no items, or show each item.
const Items = ({ counterItems, onClickRow }) => {
  if (counterItems == null || counterItems.length === 0) {
    return (
        <PrimaryText>
          <p>No counters are being tracked. You can add one or more counters and create tasks that are due based on them.</p>
          <p>For example, for a car you might add an <b>Odometer</b> to track when an oil change or tire rotation is due.</p>
        </PrimaryText>
    );
  }

  return counterItems.map( (item, index) => (
    <HourSummary key={item.id} heading={item.name} hours={item.counter} onClick={() => onClickRow(item)} />
  ));
}


function HourMeter(props) {
  // Loading/error UI
  const [StatusMessage, {showText, showError, hideStatus}] = useStatusMessage();

  // Edit Hours UI.  When to show it, and what to show.
  const [showEditCounter, setShowEditCounter] = React.useState(false);
  const [editCounter, setEditCounter] = React.useState(null);

  // Watch for when unmounted, so don't do things if this object is discarded
  // during an async operation. This happens when the edit UI is up and dismissed
  // due to the object changing and the whole UI gets re-rendered.
  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  }, [])


  // Clicked on an counter object, go edit it.
  function onClickRow(item) {
    if (props.delete) {
      setEditCounter(item);
      setShowEditCounter(true);
    }
  }

  // Add counter button hit, add a new counter.
  async function addCounter() {
    showText("Adding...");

    // Note: this can throw (e.g. network or security error)
    try {
      const newCounter = {
        counter: 0.0,
        name: 'New counter',
        itemId: props.itemId
      };
      const createdCounter = await Database.createItemCounter(newCounter);
      console.log(createdCounter);

      // Show in UI
      // TODO: Props are read-only, ya?x
      props.counterItems.push(createdCounter)

      hideStatus();
    } catch(err) {
      showError(err);
    }
  }

  async function saveCounter(counter) {
    counter.id = editCounter.id;

    // Note this can throw. The caller should catch and show the error.
    await props.save(counter);

    setShowEditCounter(false);
  }

  async function deleteCounter() {
    await props.delete(editCounter.id);
  }


  function hideEditCounter() {
    hideStatus();
    setShowEditCounter(false);
  }

  return (
    <DataCard
      header=<CardHeader text="Counters" addTapped={props.save && addCounter} />
      body={(
        <div className="text-center">
          <div className="row justify-content-center mt-2">
            <Items counterItems={props.counterItems} onClickRow={onClickRow}/>
          </div>

          {props.save && (
            <>
            <div className="card-footer">
              <StatusMessage/>
            </div>

            <EditHours
              show={showEditCounter}
              onHide={() => hideEditCounter()}
              counter={editCounter}
              save={saveCounter}
              delete={deleteCounter}
            />
            </>
          )}
        </div>
      )}
    />
  )
}

export default HourMeter

