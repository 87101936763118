// Summary of one entry in the logbook.

import { useState } from 'react';
import Button from 'react-bootstrap/Button';
// TODO: Get rid of useSharedItemPageState
import { pageLogbookEntry } from '../item/useSharedItemPageState';
import { displayDate } from '../../fleet-shared/Common';
import { Spinner, ErrorText, Confirm } from '../../components';
import { extractErrorText } from '../../fleet-shared/Common.mjs';

const DeleteButton = ({ showDelete, isDeleting, onDelete, errorText }) => {
    // Don't show anything?
    if (!showDelete) { 
        return null 
    } else if (isDeleting) {
        // Deleting, show spinner.
        return (
            <span className='col-1'><Spinner /></span>
        );
    } else {
        // Show delete button
        return (
            <>
            <i className='fa fa-trash col-1' aria-hidden='true' onClick={() => onDelete()}></i>
            <ErrorText text={errorText} />
            </>
        );
    }
}


// Single entry
const LogbookEntrySummary = ({entry, item, showDelete, onDelete, navigateTo}) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const [errorText, setErrorText] = useState(null);
    const [showConfirm, setShowConfirm] = useState(false);

    function onDetails() {
        navigateTo(pageLogbookEntry, entry.id);
    }

    // User hit delete button. Confirm.
    function handleDelete() {
        setShowConfirm(true);
    }

    // User confirmed delete, show a spinner while deleting.
    async function onConfirmDelete() {
        setIsDeleting(true);

        try {
            await onDelete(entry);
        } catch (err) {
            setErrorText(extractErrorText(err));
        }

        setIsDeleting(false);
        setShowConfirm(false);
    }

    return (
        <div className='container'>
            <div className='row pt-1'>
                <div className='col-2 text-end entered-data'>
                    {displayDate(entry.date)}
                </div>
                <div className='col-3 entered-data text-end'>
                    {entry.name}
                </div>
                <DeleteButton showDelete={showDelete} isDeleting={isDeleting} onDelete={handleDelete} errorText={errorText} />
                <Button className='col-2' variant='primary' onClick={onDetails}>
                  See Details
                </Button>
            </div>

            <Confirm
                show={showConfirm}
                onConfirm={onConfirmDelete}
                onHide = {() => setShowConfirm(false)}
                body='Are you sure?'
                confirmText='Confirm Delete'
                title={`Delete ${entry.name}`}
            />
        </div>
    );
}

export default LogbookEntrySummary;