// A component to edit a Task when entering in to the logbook. If it's
// a recurring task, updates date or counter information. Or lets user
// decide to delete the task, etc.
//
//  task = Task editing.

import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { DataCard, useStatusMessage, RadioSelection, ModalEditForm } from '../../components';
import { datePlusDays, dateStringToDate, databaseDateTimeString, buildFieldsForItem } from '../../fleet-shared/Common';
import { todayMidnight, dateMidnight, counterFromId } from '../../fleet-shared/TaskBuckets';
import { taskFields } from '../../fleet-shared/TaskFields';
import { updateTask } from '../../utilities/Database';
import DatePicker from 'react-datepicker';


function dateForFromDue(task) {
  var date = null;
  // Task have a # of days interval? If so, return that + interval.
  if (task.dueDate && task.daysInterval) {
    date = databaseDateTimeString(datePlusDays(task.dueDate, task.daysInterval));
  }
  // No date/interval.
  return date;
}

function dateForFromNow(task) {
  const today = todayMidnight();

  // Task have a # of days interval?
  // If due date and today are same, then don't return cuz it'll be same as dateFroFromDue()...
  if (task.dueDate && task.daysInterval && (today !== dateMidnight(dateStringToDate(task.dueDate)))) {
    return dateForFromDue(datePlusDays(today, task.daysInterval));
  }

  // No date/interval.
  return null;
}


function counterForFromDue(task) {
  // Task have a # counter interval? If so, return that + interval.
  if (task.dueCounter && task.counterInterval) {
    return task.dueCounter + task.counterInterval;
  }
  // No date/interval.
  return null;
}

function counterForFromNow(task, item) {
  // Task have a counter interval?
  // If due counter and now are same, then don't return cuz it'll be same as counterFroFromDue()...
  if (task.dueCounter && task.counterInterval) {
    let count = counterFromId(item, task.itemCounterId);
    if ((count !== -1) && (count !== task.dueCounter)) {
      return count + task.counterInterval;
    }
  }

  // No date/interval.
  return null;
}

// Options for selectedOption
const UpdateNothing = 'nothing';
const UpdateFromDue = 'fromDue';
const UpdateFromNow = 'fromNow';
const UpdateTo      = 'to';


// Control to show update task date UI
const UpdateDate = ({ 
  task, 
  originalDate,
  selectedDateOption, 
  setSelectedDateOption,
  updateDateFromDue,
  updateDateFromNow,
  selectedDate,
  setSelectedDate
}) => {
  // User changed the date
  function dateChanged(date) {
    var newDate = null;
    if ((date === undefined) || (date === null) || (date === "")) {
      newDate = null;
    } else {
      newDate = databaseDateTimeString(date);
    }

    // If the user changes the date, remember it and make sure "set to" is picked.
    setSelectedDate(newDate);
    if (null !== newDate) {
      setSelectedDateOption(UpdateTo);
    }
  }

  function buildOptions() {
    let options = [];

    options.push({
      text: `Leave task due date the same (${originalDate ? originalDate : 'no due date'}).`,
      value: UpdateNothing
    });

    if (updateDateFromDue) {
      options.push({
        text: `Set due date to ${updateDateFromDue} (${task.daysInterval} days from due date)`,
        value: UpdateFromDue
      });
    }

    if (updateDateFromNow) {
      options.push({
        text: `Set due date to ${updateDateFromNow} (${task.daysInterval} days from today)`,
        value: UpdateFromNow
      });
    }

    options.push({
      text: 'Set to date:',
      value: UpdateTo,
      children: 
        <div className='container d-flex align-items-center justify-content-center col-4'>
          <DatePicker
            selected={dateStringToDate(selectedDate())}
            onChange={dateChanged}
            dateFormat="MMM d, yyyy h:mm aa"
            isClearable
            showTimeSelect
            placeholderText="mm/dd/yyyy"

          />
        </div>
    });

    return options;
  }

  return (
    <RadioSelection
      options={buildOptions()}
      option={selectedDateOption}
      setOption={setSelectedDateOption}
    >
    </RadioSelection>
  );
}

// Control to show update task counter UI
const UpdateCounter = ({ 
  task, 
  originalCounter,
  selectedCounterOption, 
  setSelectedCounterOption, 
  updateCounterFromDue, 
  updateCounterFromNow,
  updateCounterValue,
  setUpdateCounterValue
}) => {

  function counterChanged(event) {
    const target = event.target;
    if (target.value === '') {
      setUpdateCounterValue(null);
    } else {
      try {
        const value = parseFloat(target.value);
        setSelectedCounterOption(UpdateTo);
        setUpdateCounterValue(value);
      } catch {
        // Don't do anything if not a number (parseFloat threw)
      }
    }
  }

  function buildOptions() {
    let options = [];

    options.push({
      text: `Leave task due counter the same (${(originalCounter > 0) ? originalCounter : 'no counter'}).`,
      value: UpdateNothing
    });

    if (updateCounterFromDue) {
      options.push({
        text: `Set task due counter to ${updateCounterFromDue} (${task.counterInterval} ticks from due counter)`,
        value: UpdateFromDue
      });
    }

    if (updateCounterFromNow) {
      options.push({
        text: `Set task due counter to ${updateCounterFromNow} (${task.counterInterval} ticks from current counter)`,
        value: UpdateFromNow
      });
    }

    options.push({
      text: 'Set due counter to:',
      value: UpdateTo,
      children: 
        <div className='container d-flex align-items-center justify-content-center col-4'>
          <input 
            name='Counter'
            type='number'
            step={0.1}
            placeholder='Counter' 
            value={updateCounterValue || ''} 
            onChange={counterChanged} 
          />

        </div>
    });


    return options;
  }

  return (
    <RadioSelection
      options={buildOptions()}
      option={selectedCounterOption}
      setOption={setSelectedCounterOption}
    />
  );
}

export const LogbookUpdateTask = ({ task, item }) => {
  // Constants based on the task
  const [originalDate] = useState(task.dueDate);
  const [originalCounter] = useState(task.dueCounter);
  const [updateCounterFromDue] = useState(counterForFromDue(task));
  const [updateCounterFromNow] = useState(counterForFromNow(task, item));
  const [updateDateFromDue] = useState(dateForFromDue(task));
  const [updateDateFromNow] = useState(dateForFromNow(task));

  // Which option the user selects for updating teh date/counter
  const [selectedDateOption, setSelectedDateOption] = useState(UpdateNothing);  //defaultDateOption(task));
  const [selectedCounterOption, setSelectedCounterOption] = useState(UpdateNothing); // defaultCounterOption(task, item));

  // Show full task editing UI
  const [showEditTask, setShowEditTask] = useState(false);

  // For showing loading status.
  const [StatusMessage, {showText, showError, hideStatus}] = useStatusMessage();

  // User picked option for updating task, go update in database.
  async function handleSave(saveTask) {
    try {
      showText('Saving');

      // Copy the known fields from the task saving to the task.
      for (var i=0; i < taskFields.length; i++) {
        task[taskFields[i].key] = saveTask[taskFields[i].key];
      }
      await updateTask(task, item);

      // Update UI
      hideStatus();
      setShowEditTask(false);
    } catch (err) {
      showError(err);
    }
  }

  async function setSelectedDate(date) {
    task.dueDate = date;
    await dateSelectionChanged(UpdateTo);
  }

  function selectedDate() {
    return task.dueDate;
  }

  async function setUpdateCounterValue(value) {
    task.dueCounter = value;
    await counterSelectionChanged(UpdateTo);
  }

  function updateCounterValue() {
    return task.dueCounter;
  }

  // User changed the date selection, update the task's date and save to database.
  async function dateSelectionChanged(selectedDateOption) {
    setSelectedDateOption(selectedDateOption);

    try {
      // Update the dueDate in task based on user's pick.
      switch (selectedDateOption) {
      case UpdateNothing:
        // Make original date
        task.dueDate = originalDate;
        break;
      case UpdateFromDue:
        task.dueDate = updateDateFromDue;
        break;
      case UpdateFromNow:
        task.dueDate = updateDateFromNow;
        break;
      case UpdateTo:
        if (null !== selectedDate()) {
          task.dueDate = selectedDate();
        } else {
          throw new Error('Please specify a date.')
        }
        break;
      default:
        throw new Error('Unexpected date option in saveTask');
      }

      await handleSave(task);
    } catch (err) {
      showError(err);
    }
  }

  // User changed the counter selection, update the task's date and save to database.
  async function counterSelectionChanged(selectedCounterOption) {
    setSelectedCounterOption(selectedCounterOption);

    try {
      // Update the due counter in task based on user's pick.
      switch (selectedCounterOption) {
      case UpdateNothing:
        // Set to original
        task.dueCounter = originalCounter;
        break;
      case UpdateFromDue:
        task.dueCounter = updateCounterFromDue;
        break;
      case UpdateFromNow:
        task.dueCounter = updateCounterFromNow;
        break;
      case UpdateTo:
        if (updateCounterValue === null) {
          throw new Error('Please specify a counter value.')
        }
        task.dueCounter = updateCounterValue;
        break;
      default:
        throw new Error('Unexpected counter option in saveTask');
      }
      await handleSave(task);
    } catch (err) {
      showError(err);
    }
  }

  function updateTaskClicked() {
    setShowEditTask(true);
  }

  return (
    <div>
      <DataCard
        header='How would you like to update the task due date?'
        body=<UpdateDate 
          task={task} 
          originalDate={originalDate}
          selectedDateOption={selectedDateOption} 
          setSelectedDateOption={dateSelectionChanged} 
          updateDateFromDue={updateDateFromDue}
          updateCounterFromNow={updateDateFromNow}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
      />
      <DataCard
        header='How would you like to update the task due counter?'
        body=<UpdateCounter 
          task={task} 
          originalCounter={originalCounter}
          selectedCounterOption={selectedCounterOption} 
          setSelectedCounterOption={counterSelectionChanged} 
          updateCounterFromDue={updateCounterFromDue}
          updateCounterFromNow={updateCounterFromNow}
          updateCounterValue={updateCounterValue()}
          setUpdateCounterValue={setUpdateCounterValue}
        />
      />

      <Button className='mt-2' variant='primary' onClick={updateTaskClicked}>
          Update Other Task Fields
      </Button>

      <ModalEditForm
        title='Edit Task'
        show={showEditTask}
        onHide={() => setShowEditTask(false)}
        fields={buildFieldsForItem(taskFields, item)}
        item={task}
        save={handleSave}
      />

      <StatusMessage/>
    </div>
  );
}