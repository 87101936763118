// Shows a list of tasks. Has an URL of the form:
//    /item/:itemId/tasks/:taskList
// taskList = 'all' all tasks for the given ItemId
//          = 'overdue' all overdue tasks
//          = 'soon' for coming soon tasks.
//
// When called from another page, can include state so don't need to reload
// everything. state contains:
//    name - Name of this list (for breadcrumbs)
//    item - Item the tasks belong to
//    bucket - Bucket to show links to (see utilities/TaskBuckets)

import { DataCard, CardHeader, HourMeter } from '../../components';
import { useParams } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { taskUrlToBucketName } from '../../fleet-shared/TaskBuckets';
import { ItemBreadcrumbs } from '../item/ItemBreadcrumbs';
import { TaskTable } from './TaskTable';
import { useTaskQuery } from './TaskQuery';
import { useStatusMessage } from '../../components';
import { createTaskPogoTask } from '../../graphql/mutations';
import { useNavigate } from 'react-router-dom';
import { urlForEditTaskId } from '../../fleet-shared/Urls.mjs';


import { generateClient } from 'aws-amplify/api';
const client = generateClient();

export const SortOverdue = 'o';
export const SortAssigned = 'a';
export const SortPriority = 'p';

const SortSelection = ({ sortDirection, setSortDirection }) => {
  return (
    <div className="btn-group">
      <button
        className={`btn btn-sm ${sortDirection === SortOverdue ? 'btn-primary' : 'btn-outline-primary'}`}
        onClick={() => setSortDirection(SortOverdue)}
      >
        Overdue First (Date then Counter)
      </button>
      <button
        className={`btn btn-sm ${sortDirection === SortAssigned ? 'btn-primary' : 'btn-outline-primary'}`}
        onClick={() => setSortDirection(SortAssigned)}
      >
        Assigned To (then priority)
      </button>
      <button
        className={`btn btn-sm ${sortDirection === SortPriority ? 'btn-primary' : 'btn-outline-primary'}`}
        onClick={() => setSortDirection(SortPriority)}
      >
        Priority
      </button>
    </div>
  );
}




const Tasks = () => {
  // Passed on URL, the item the task(s) belong to. Optionally, the single taskId being shown.
  const { itemId, listType } = useParams();
  const [item, setItem] = useState(null);

  // User can sort the tasks by different criteria.
  const [sortDirection, setSortDirection] = useState(SortOverdue);

  // Does the query to get the item and its tasks.
  const fetchItems = useTaskQuery(itemId, listType, sortDirection, setItem);

  const [StatusMessage, {showText, showError, hideStatus}] = useStatusMessage();

  const navigate = useNavigate();


  // const { showEditTaskPopup, TaskEditor } = useEditTaskPopup({ 
  //   item: item, 
  //   taskEdited: (task) => {
  //     // Show the task has been edited...
  //     // updateTaskBucket();
  //   }, 
  //   taskDeleted: (id) => {
  //     // Remove the task from the model.
  //     // updateTaskBucket();
  //   } }
  // );

  // When the user adds or edits an item in the task list, we need to update the taskBucket so the UI udpates.
  // const updateTaskBucket = useCallback(() => {
  //   let buckets = taskBuckets(item);
  //   let newTaskBucket = buckets.find((bucket) => bucket.name === taskBucket.name);
  //   if (newTaskBucket) {
  //     setTaskBucket(newTaskBucket);
  //   }
  // }, [item, taskBucket.name, setTaskBucket]);
  // }, []);

  // User hit the add button, add a new task and edit it.
  const addTapped = useCallback(async () => {
    try {
      // Create the task.
      showText('Creating new task...');

      let newTask = {
        name: 'New Task',
        itemId: itemId,
        priority: 0,
        assignedEmail: '',
        rank: new Date().getDate(),
        dueDate: null
      };

      // Note: this can throw (e.g. network or security error)
      const created = await client.graphql({ query: createTaskPogoTask, variables: { input: newTask }});
      var task = created.data.createTaskPogoTask;

      hideStatus();

      // Navigate the the task editing page.
      navigate(urlForEditTaskId(itemId, task.id));
    } catch (err) {
      showError(err);
    }
  }, [showText, hideStatus, showError, navigate, itemId]);



  // This is a function for creating test data. It's not used in production. I use it
  // to create a ton of tasks, so that nextToken comes in to play on database queries.
  // function createTestTasks() {
  //   let tasks = [];
  //   for (var i=100; i < 1000; i++) {
  //     let task = createTask(item, `Task ${i+1}`);
  //     tasks.push(task);
  //   }
  //   return tasks;
  // }


  return (
    <div className='pb-2'>
      <ItemBreadcrumbs item={item} />
      <StatusMessage />

      {item && item.itemCounter.items && item.itemCounter.items.length && 
        <HourMeter 
          itemId={itemId} 
          counterItems={item && item.itemCounter.items} 
          />
      }

      <DataCard
        header={
          <CardHeader 
            text={taskUrlToBucketName(listType)}
            belowText={<SortSelection sortDirection={sortDirection} setSortDirection={setSortDirection} />}
            addTapped={addTapped} 
          />}
        body={
          <div className='tasks-container'>
            <TaskTable fetchItems={fetchItems} />
            <button className='btn btn-success mt-2' onClick={() => addTapped }>Add</button>
            <StatusMessage />
          </div>
        }
      />
    </div>
  );
}

export default Tasks;
