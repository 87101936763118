// Data driven form component.
//
// item - item editing
// fields - map of fields: { name: key: type: }
// saveItem - async function to save the edited item
// deleteItem - optional async function to delete the item, null means no delete button.
// handleSecondaryButton - optional async function for a secondary button.
// secondaryButtonText - optional text for secondary button
// secondaryButtonVariant - type of secondary button (primary, secondary, danger, etc)
// children  - optional children for the control.
// 

import React, { useState, useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useStatusMessage } from '.';
import DatePicker from 'react-datepicker';
import Confirm from './Confirm';
import { databaseDateTimeString, dateStringToDate } from '../fleet-shared/Common.mjs';
import { NewTaskName, NewItemName } from '../utilities/Database.mjs';


// One field in the form.
const Field = ( {field, state, setState, setFocus} ) => {
  // In the DB, dates are stored in an ISO 8601 Date string ("YYYY-MM-DD").
  // The DatePicker control takes a Javascript Date object, translate to/from that.
  function dateFieldTextToDate(key) {
    if (state) {
      let dateString = state[key];
      return dateStringToDate(dateString);
    }
    return null;
  }

  function dateFieldChanged(key, date) {
    var newDate = null;
    if ((date === undefined) || (date === null) || (date === "")) {
      newDate = null;
    } else {
      newDate = databaseDateTimeString(date);
    }

    // Update UI
    let newState = {...state};
    newState[key] = newDate;
    setState(newState);
  }

  // Given an ID from a select, find it and return the name.
  // TODO: O(n)
  function selectValueFromId(field, id) {
    for (var i=0; i < field.selects.length; i++) {
      if (field.selects[i].id === id) {
        return field.selects[i].name;
      }
    }
    return null;
  }

    // Given a value from a select, find it and return the id.
  // TODO: O(n)
  function selectIdFromValue(field, value) {
    for (var i=0; i < field.selects.length; i++) {
      if (field.selects[i].name === value) {
        return field.selects[i].id;
      }
    }
    // This shouldn't happen
    console.log('Bad value? ' + value);
    return null;
  }


  // Converts the input, which if often a string to the correct type in our model.
  function coerceType(field, value) {
    // Empty? Return null.
    if ((null === value) || ("" === value)) {
      // TODO: I don't love this
      // Amplify's graphql API (DynamoDB?): if it's used in an index as a sortKeyField, it cannot be null or empty.
      // So, if it's empty return a space.
      if (field.isSortField === true) {
        return ' ';
      }
      return null;
    }

    switch (field.type) {
    case 'text':
    default:
      // Turn in to a string and trim it.
      const trimmed = String(value); 
      // *** I decided to not trim, as editing notes wouldn't add spaces.
      // String(value).trim();

      // Empty string?
      if (trimmed.length === 0) {
        // TODO: I don't love this
        // Amplify's graphql API (DynamoDB?): if it's used in an index as a sortKeyField, it cannot be null or empty.
        // So, if it's empty return a space.
        if (field.isSortField === true) {
          return ' ';
        }

        // Don't put into database...
        return null;
      }

      return trimmed;
    case 'date':
      return value; 
    case 'number':
      return parseFloat(value);
    case 'select':
      return selectIdFromValue(field, value);
    }
  }

  // Given an "id" for a select, return the "name" from the item's selects.
  function selectDefaultValue(field) {
    let value = state[field.key];
    return selectValueFromId(field, value);
  }

  function handleChange(field, event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    //const name = target.name;

    let newState = {...state};
    newState[field.key] = coerceType(field, value);

    setState(newState);
  }

  function outputOption(option) {
    return (
      <option key={option.index} value={option.name}>{option.name}</option>
    );
  }

  // If it's a text field and has a default value for when an item is created, select all when it gets focus.
  // So, when I create a new object I give it a name like 'New Item'. When the user taps on name field, I want
  // to select all so they can type over it.
  const handleFocus = (event) => {
    if ((event.target.value === NewTaskName) || (event.target.value === NewItemName)) {
      event.target.select();
    }
  }


  // Get the value of a field from the state.
  function fieldValue(field) {
    // Make sure we have a state and the field is in it. For sortKeyFields, I use a space to represent
    // an empty string in the database, so if I've done that, drop thru and return "".
    if (state && state[field.key] && state[field.key] !== ' ') {
      return state[field.key];
    }
    return "";
  }


  // d-flex needed for justify-content- and align-items-
  // justify-content-start to left justify
  const InputFieldClass = 'col-8 d-flex justify-content-start'
  const ShortFieldClass = 'col-2 d-flex justify-content-start'

  // This returns the HTML for an input field.
  function outputInputField(field) {
    switch (field.type) {
    case 'date':
      return (
        <DatePicker
          className={InputFieldClass}
          selected={dateFieldTextToDate(field.key)}
          onChange={(date) => dateFieldChanged(field.key, date)}
          dateFormat="MMM d, yyyy h:mm aa"
          isClearable
          showTimeSelect
          placeholderText="mm/dd/yyyy hh:mm"
        />
      );

    // Numbers have a step
    case 'number':
      if (setFocus) {
        return (
          <input 
            className={ShortFieldClass}
            name={field.name} 
            type={field.type} 
            step={0.1}
            placeholder={field.name} 
            value={fieldValue(field)} 
            autoFocus
            onChange={event => { 
              handleChange(field, event)
            }} />
        );
      } else {
        return (
          <input 
            className={ShortFieldClass}
            name={field.name} 
            type={field.type} 
            step={0.1}
            placeholder={field.name} 
            value={fieldValue(field)} 
            onChange={event => { 
              handleChange(field, event)
            }} />
        );
      }

    case 'select':
      return (
        <select 
          name={field.name} 
          className={ShortFieldClass}
          defaultValue={selectDefaultValue(field)} 
          onChange={event => { 
            handleChange(field, event) 
          }}>
            {
              field.selects.map((option, index) => outputOption(option))
            }
        </select>
      );

    // Default is text.
    case 'text':
    default:
      if (setFocus) {
        return (
          <input 
            className={InputFieldClass}
            name={field.name} 
            type={field.type} 
            placeholder={field.name} 
            value={fieldValue(field)} 
            autoFocus
            onFocus={handleFocus}
            onChange={event => { 
              handleChange(field, event)
            }} />
        );
      } else {
        return (
          <input 
            className={InputFieldClass}
            name={field.name} 
            type={field.type} 
            placeholder={field.name} 
            value={fieldValue(field)} 
            onFocus={handleFocus}
            onChange={event => { 
              handleChange(field, event)
            }} />
        );
      }
    }
  }

  // d-flex is essential in order for justify-content- and align-items- to work
  // justify-content-end moves your labels to the right
  // align-items-end moves your labels down so that they're aligned with forms
  //pe-3 sets padding-right
  return (
    <div className="container">
      <div className="d-flex flex-row">
        <label className="col-4 d-flex justify-content-end align-items-end pe-3">{field.name}</label>
        { outputInputField(field) }
      </div>
    </div>
  );
}

export const EditForm = ({ 
  item, 
  fields, 
  saveItem, 
  deleteItem, 
  handleSecondaryButton, 
  secondaryButtonText,
  secondaryButtonVariant,
  children 
}) => {
  // Watch for when unmounted, so don't do things if this object is discarded
  // during an async operation.
  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  }, [])


  // Loading/error UI
  const [StatusMessage, {showText, showError, hideStatus}] = useStatusMessage();

  // Initial state.
  const [state, setState] = useState(item);

  // Confirm delete
  const [showConfirm, setShowConfirm] = useState(false);

  async function handleSave(event) {
    // Don't submit
    event.preventDefault();
    
    showText("Saving");

    // Get data out of fields
    let editedItem = {};
    for (var i=0; i < fields.length; i++) {
      const key = fields[i].key;
      let value = state[key];
      if (value != null) {
        editedItem[key] = value;
      } else {
        // If the original item had this field, it needs to be removed by
        // setting the value to null.
        if (item[key]) {
          editedItem[key] = null;
        }
      }
    }

    try {
      await saveItem(editedItem);
      if (!mounted.current) { return; }
      hideStatus();
    } catch (err) {
      showError(err);
    }
  }

  // When delete tapped, show confirm.
  function handleDelete() {
    setShowConfirm(true);
  }


  // Delete was confirmed, ask the parent to delete us.
  async function handleDeleteConfirmed() {
    showText("Deleting");

    try {
      setShowConfirm(false);
      await deleteItem();
      if (mounted.current) {
        hideStatus()
      }
    } catch (err) {
      showError(err);
    }
  }

  function secondaryButton() {
    hideStatus();
    if (handleSecondaryButton) {
      handleSecondaryButton();
    }
  }

  const DeleteButton = () => {
    if (deleteItem) {
      return (
        <>
          <Button variant="danger" className="mr-2 col-3" style={{float:'right'}} onClick={handleDelete}>
              Delete
          </Button>
          <Confirm
            show={showConfirm}
            onConfirm={handleDeleteConfirmed}
            onHide = {() => setShowConfirm(false)}
            body='Are you sure?'
            confirmText='Confirm Delete'
            title={`Delete`}>
          </Confirm>
        </>
      );
    } else {
      return null;
    }
  }

  return (
    <Form onSubmit={handleSave}>
      {
        fields.map((field, index) => <Field key={field.key} field={field} state={state} setState={setState} setFocus={index===0}/>)
      }

      {children}

      <StatusMessage/>

      <div className="container m-2">
        <Button type="submit" className="mx-2 col-3">
          Save
        </Button>

        { handleSecondaryButton && (
          <Button variant={secondaryButtonVariant} className="col-3" onClick={secondaryButton}>
            {secondaryButtonText}
          </Button>

          )
        }

        <DeleteButton />
      </div>
    </Form>
  );
}


EditForm.defaultProps = {
  handleSecondaryButton: null,
  secondaryButtonText: 'Cancel',
  secondaryButtonVariant: 'secondary'
};

export default EditForm;
