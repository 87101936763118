import { EditTask } from "../../components";
import { useState, useCallback } from "react";
import { updateTask, deleteTask } from "../../utilities/Database.mjs";

// Shared task editing UI. Pass in the item the task belongs to and callbacks for when the task has been edited or deleted.
// Returns a function to call to show the edit task popup and the popup itself.
export const useEditTaskPopup = ({ item, taskEdited, taskDeleted }, showNow = false) => {
  const [showEditTask, setShowEditTask] = useState(showNow);
  const [editTask, setEditTask] = useState(null);

  // Passed to EditTask.  When the Save button is pressed, this is called.
  // throws on errors.
  const saveEditedTask = useCallback(async (task) => {
    task.id = editTask.id;
    task.itemId = item.id;

    // Update the database and model. This can throw, caller catches and shows error...
    await updateTask(task, item, item.tasks.items);

    // Call the parent to update the task list UI
    taskEdited(task);

    setShowEditTask(false);
  }, [editTask, item, taskEdited]);

  // Passed to EditTask, deletes the given taskId
  const onDeleteTask = useCallback(async(id) => {
    // Update the database and item.tasks.items. Can throw, caller catches and shows error.
    await deleteTask(id, item.tasks.items);

    // Update UI
    setShowEditTask(false);
    taskDeleted(id);
  }, [item, taskDeleted]);
  
  const TaskEditor = <EditTask
    show={showEditTask}
    onHide={() => setShowEditTask(false)}
    task={editTask}
    item={item}
    save={saveEditedTask}
    delete={onDeleteTask}
  />

  function showEditTaskPopup(task) {
    setEditTask(task);
    setShowEditTask(true);
  }

  return { showEditTaskPopup, TaskEditor }
}